var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_task_main_widget_wrapper_page bbox",
      style: { "z-index": _vm.index },
    },
    [
      _c(
        "div",
        {
          staticClass: "contents bbox",
          class: { relevancy: _vm.parent },
          style: { "z-index": _vm.index },
        },
        [
          _c("widget-title", {
            attrs: { p_task_detail: _vm.p_task_detail },
            on: { handleAdd: _vm.touchAdd },
          }),
          _vm.p_task_detail && _vm.p_task_detail.type
            ? [
                _vm.p_task_detail.type === 1
                  ? _c("content-text", {
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 2
                  ? _c("content-number", {
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 3
                  ? _c("content-money", {
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 4
                  ? _c("content-time", {
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 6
                  ? _c("content-file", {
                      ref: "file",
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 7
                  ? _c("content-hyperlink", {
                      ref: "hyperlink",
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 9
                  ? _c("content-picture", {
                      ref: "picture",
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 10
                  ? _c("content-contact", {
                      ref: "contact",
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
                _vm.p_task_detail.type === 11
                  ? _c("content-wisdom", {
                      ref: "wisdom",
                      attrs: { p_task_detail: _vm.p_task_detail },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.p_task_detail.islink
            ? _c(
                "div",
                { staticClass: "relevancy_wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "title_group d-flex align-center bbox" },
                    [
                      _c(
                        "div",
                        { staticClass: "name_group d-flex align-center" },
                        [
                          _c("div", {
                            staticClass: "icon cp",
                            class: {
                              close: !_vm.p_task_detail.switch_relevancy,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.swicthRelevancy()
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "name un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.swicthRelevancy()
                                },
                              },
                            },
                            [_vm._v("关联区")]
                          ),
                          _c("relevancy-add-button", {
                            attrs: {
                              large_task: _vm.large_task,
                              data: _vm.data,
                              backup: _vm.backup,
                              p_task_detail: _vm.p_task_detail,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "line" }),
                      _c("div", {
                        staticClass: "delete",
                        on: {
                          click: function ($event) {
                            return _vm.deleteRelevancy(_vm.p_task_detail)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm.p_task_detail.switch_relevancy
                    ? _c("widget-container", {
                        ref: "container",
                        staticClass: "relevancy_container",
                        attrs: {
                          parent: _vm.p_task_detail,
                          large_task: _vm.large_task,
                          data: _vm.data,
                          backup: _vm.backup,
                          list: _vm.p_task_detail.controlLinkList,
                        },
                        on: { setZIndex: _vm.setZIndex },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "relevancy_hint bbox" },
                                    [
                                      _vm._v(
                                        "点击上方加号或拖拽右侧控件至此进行添加"
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2585535959
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.islock
            ? _c(
                "div",
                {
                  staticClass: "fun_group d-flex align-center justify-end bbox",
                },
                [
                  _vm.projection_screen_status
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "button projection_screen",
                            class: { active: _vm.projection_screen_start },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doProjectionScreen()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.projection_screen_start ? "结束" : "开始"
                              ) + "投屏"
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.islock
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "button btn_dark",
                            on: {
                              click: function ($event) {
                                return _vm.doSendWidget()
                              },
                            },
                          },
                          [_vm._v("完成")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "button btn_light",
                            on: {
                              click: function ($event) {
                                return _vm.doRepeal()
                              },
                            },
                          },
                          [_vm._v("撤销")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      0 && _vm.expand_status
        ? _c("expand-bar", {
            attrs: { remindList: _vm.item.remindList, recycle: _vm.recycle },
            on: { handleOpenReminder: _vm.handleOpenReminder },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }