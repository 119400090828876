import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=364f4949&scoped=true"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"
import style0 from "./Contact.vue?vue&type=style&index=0&id=364f4949&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364f4949",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('364f4949')) {
      api.createRecord('364f4949', component.options)
    } else {
      api.reload('364f4949', component.options)
    }
    module.hot.accept("./Contact.vue?vue&type=template&id=364f4949&scoped=true", function () {
      api.rerender('364f4949', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ClassesTask/modules/Main/Widget/Content/Contact.vue"
export default component.exports