var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "components_task_main_widget_content_picture_page bbox",
    },
    [
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { accept: "image/*", type: "file", multiple: "" },
        on: { change: _vm.fileChange },
      }),
      _vm.switch_waterfall
        ? _c(
            "div",
            {
              directives: [{ name: "masonry", rawName: "v-masonry" }],
              staticClass: "waterfall_container",
              attrs: {
                "transition-duration": "0.3s",
                "item-selector":
                  ".components_task_main_widget_content_part_picture_page",
                "fit-width": "true",
                id: "masonry",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    { name: "masonry-tile", rawName: "v-masonry-tile" },
                  ],
                  staticClass: "waterfall bbox",
                },
                [
                  _vm._l(_vm.p_task_detail.fileList, function (val) {
                    return [
                      val.type != 1
                        ? _c("picture-part", {
                            key: val.custom_index || val.id,
                            attrs: {
                              file: val,
                              width: _vm.width,
                              preview_list: _vm.preview_list,
                              editable: _vm.editable,
                              system: _vm.system,
                            },
                            on: {
                              handleDelete: _vm.deleteFile,
                              handleSelect: _vm.selectFile,
                              handleDownload: _vm.downloadFile,
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }