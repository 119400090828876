var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_time_page d-flex align-center bbox",
    },
    [
      _c("time-type", {
        attrs: {
          timetype: _vm.p_task_detail.timetype,
          editable: _vm.editable,
          other_lock: _vm.other_lock,
          p_task_detail: _vm.p_task_detail,
        },
        on: { handleChange: _vm.changeTimeType },
      }),
      _vm.p_task_detail.timetype != 3
        ? _c("time-for-date", {
            attrs: {
              content: String(_vm.p_task_detail.content),
              editable: _vm.editable,
              other_lock: _vm.other_lock,
              p_task_detail: _vm.p_task_detail,
            },
            on: { handleChange: _vm.changeDate },
          })
        : _vm._e(),
      _vm.p_task_detail.timetype != 2
        ? _c("time-for-time", {
            attrs: {
              content: String(_vm.p_task_detail.content),
              editable: _vm.editable,
              other_lock: _vm.other_lock,
              p_task_detail: _vm.p_task_detail,
            },
            on: { handleChange: _vm.changeTime },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }