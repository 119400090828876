var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_task_main_widget_content_part_picture_page bbox",
      class: { uploading: _vm.uploading, select: _vm.select },
      style: { width: `${_vm.width}px`, height: `${_vm.height + 30}px` },
    },
    [
      _c(
        "div",
        {
          staticClass: "img_group un_sel",
          style: { width: `${_vm.width}px`, height: `${_vm.height}px` },
        },
        [
          _c(
            "el-image",
            {
              ref: "image",
              staticClass: "image",
              attrs: {
                src: _vm.file.url
                  ? _vm.file.status === "no_upload"
                    ? _vm.file.preview
                    : `${_vm.$img_url}${_vm.file.url}`
                  : _vm.file.preview,
                "preview-src-list": _vm.preview_list,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "placeholder" },
                  slot: "placeholder",
                },
                [
                  _c("img", {
                    style: {
                      width: `${_vm.width}px`,
                      height: `${_vm.height}px`,
                    },
                    attrs: {
                      src: _vm.file.url
                        ? _vm.file.status === "no_upload"
                          ? _vm.file.preview
                          : `${_vm.$img_url}${_vm.file.url}`
                        : _vm.file.preview,
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "mask" }, [
            _vm.info_group
              ? _c(
                  "div",
                  {
                    staticClass:
                      "info_group d-flex flex-column justify-space-between align-start",
                    on: {
                      click: function ($event) {
                        return _vm.imagePreview()
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "select cp",
                      class: { on: _vm.file.select },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectFile()
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "fun_group bbox d-flex justify-space-between align-end",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "info_box" },
                          [
                            _vm.info_status
                              ? [
                                  _vm.file.filesize
                                    ? _c("div", { staticClass: "size" }, [
                                        _vm._v(
                                          _vm._s(_vm.memory(_vm.file.filesize))
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.file.createTime
                                    ? _c("div", { staticClass: "time" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              _vm.file.createTime
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm.failed_status
                              ? _c("div", { staticClass: "failed" }, [
                                  _vm._v("上传失败"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "fun_box d-flex flex-column" },
                          [
                            _vm.editable
                              ? _c("div", {
                                  staticClass: "button cp delete",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteFile()
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.file.url && !_vm.failed_status && _vm.download
                              ? _c("div", {
                                  staticClass: "button cp download",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.downloadFile()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.uploading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "upload_group d-flex align-center justify-center",
                  },
                  [
                    _c("div", { staticClass: "uploading d-flex flex-column" }, [
                      _c("div", { staticClass: "icon" }),
                      _vm.file.progress
                        ? _c("div", { staticClass: "progress" }, [
                            _vm._v(_vm._s(_vm.file.progress) + "%"),
                          ])
                        : _vm._e(),
                    ]),
                    0
                      ? _c("div", { staticClass: "cancal" }, [
                          _vm._v("取消上传"),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "name bbox",
          on: {
            click: function ($event) {
              return _vm.imagePreview()
            },
          },
        },
        [_vm._v(_vm._s(_vm.file.filename))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }