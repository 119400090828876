var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_part_file_page bbox d-flex align-center",
      class: { failed: _vm.failed, cp: _vm.preview },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.previewFile()
        },
      },
    },
    [
      _c("div", {
        staticClass: "file_icon flex-shrink-0",
        class: [_vm.$tools.getExtName(_vm.file.filename)],
      }),
      _c(
        "div",
        { staticClass: "info_group d-flex align-center justify-space-between" },
        [
          _c(
            "div",
            {
              staticClass:
                "detail_group d-flex flex-column justify-space-between align-start",
            },
            [
              _c("div", { staticClass: "filename" }, [
                _vm._v(_vm._s(_vm.file.filename)),
              ]),
              _vm.uploading
                ? _c(
                    "div",
                    { staticClass: "uploading d-flex align-center un_sel" },
                    [
                      _c("div", { staticClass: "progress_text" }, [
                        _vm._v(_vm._s(_vm.memory(_vm.file.filesize))),
                      ]),
                      _vm.file.progress && _vm.file.progress != 0 && !_vm.failed
                        ? _c("div", { staticClass: "progress_text ml-6" }, [
                            _vm._v(
                              "正在上传 " + _vm._s(_vm.file.progress) + "%"
                            ),
                          ])
                        : _vm._e(),
                      !_vm.file.progress ||
                      (_vm.file.progress == 0 && !_vm.failed)
                        ? _c("div", { staticClass: "progress_text ml-6" }, [
                            _vm._v("等待上传"),
                          ])
                        : _vm._e(),
                      _vm.failed
                        ? _c("div", { staticClass: "btn failed" }, [
                            _vm._v("上传失败"),
                          ])
                        : _vm._e(),
                      _vm.failed
                        ? _c(
                            "div",
                            {
                              staticClass: "btn cp re_upload",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.reUpload.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("重新上传")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.details
                ? _c("div", { staticClass: "details d-flex align-center" }, [
                    _c("div", { staticClass: "filesize" }, [
                      _vm._v(_vm._s(_vm.memory(_vm.file.filesize))),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm._f("formatDate")(_vm.file.createTime))),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.show_icon && !_vm.shopping_mail
            ? _c(
                "div",
                {
                  staticClass: "fun_group d-flex align-center flex-shrink-0",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$api.stopPropagation($event)
                    },
                  },
                },
                [
                  _vm.download
                    ? _c("div", {
                        staticClass: "button download",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.downloadFile()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.deleteable
                    ? _c("div", {
                        staticClass: "button delete",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.deleteFile()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.selectable
                    ? _c("div", {
                        staticClass: "button select",
                        class: { on: _vm.file.select },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.selectFile()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.sort
                    ? _c("div", { staticClass: "button sort" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.file.progress && !_vm.file.url
            ? _c("div", {
                staticClass: "progress",
                style: { width: `${_vm.file.progress || 0}%` },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }