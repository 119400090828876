<template>
  <div
    class="components_task_main_widget_content_part_picture_page bbox"
    :style="{width: `${width}px`, height: `${height+30}px`}"
    :class="{uploading, select}"
  >
    <div class="img_group un_sel"
      :style="{width: `${width}px`, height: `${height}px`}"
    >
      <el-image 
        ref="image"
        class="image"
        :src="file.url ? (file.status === 'no_upload' ? file.preview : `${$img_url}${file.url}`) : file.preview"
        :preview-src-list="preview_list"
      >
        <!-- 使用原图片站位 解决瀑布流初始加载高度不正确 -->
        <div slot="placeholder" class="image-slot">
          <img
            :src="file.url ? (file.status === 'no_upload' ? file.preview : `${$img_url}${file.url}`) : file.preview"
            :style="{width: `${width}px`, height: `${height}px`}"
          >
        </div>
        
      </el-image>
      <div class="mask">
        <div class="info_group d-flex flex-column justify-space-between align-start"
          v-if="info_group"
          @click="imagePreview();"
        >
          <div class="select cp"
            :class="{on: file.select}"
            @click.stop="selectFile();"
          ></div>
          <div class="fun_group bbox d-flex justify-space-between align-end">
            <div class="info_box">
              <template
                v-if="info_status"
              >
                <div class="size"
                  v-if="file.filesize"
                >{{memory(file.filesize)}}</div>
                <div class="time"
                  v-if="file.createTime"
                >{{file.createTime | formatDate}}</div>
              </template>

              <div class="failed"
                v-if="failed_status"
              >上传失败</div>
            </div>
            <div class="fun_box d-flex flex-column">
              <div class="button cp delete"
                @click.stop="deleteFile();"
                v-if="editable"
              ></div>
              <div class="button cp download"
                v-if="file.url && !failed_status && download"
                @click.stop="downloadFile();"
              ></div>
            </div>
          </div>
        </div>
        <div class="upload_group d-flex align-center justify-center"
          v-if="uploading"
        >
          <div class="uploading d-flex flex-column">
            <div class="icon"></div>
            <div class="progress"
              v-if="file.progress"
            >{{file.progress}}%</div>
          </div>
          <div class="cancal" v-if="0">取消上传</div>

        </div>
      </div>
    </div>
    <div class="name bbox"
      @click="imagePreview();"
    >{{file.filename}}</div>
  </div>
</template>
<script>

export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
   
    fileList: {
      type: Array,
      default: function(){
        return []
      },
    },
    file:{
      type: Object,
      default: null,
    },
    preview_list: {
      type: Array,
      default: function(){
        return []
      }
    },
    editable: {
      type: Boolean,
      default: true,
    },
    system: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height(){
      const {file, width} = this;
      const height = (width * file.height) / file.width;
      return height;
    },
    uploading(){
      const {file} = this;
      const {url} = file;
      if(url){
        return false;
      }
      return true;
    },
    info_group(){
      const {file} = this;
      const {url, status} = file;
      if(url){
        return true;
      }
      if(status === 'failed'){
        return true;
      }
      return false;
    },
    info_status(){
      const {file} = this;
      const {status} = file;
      if(status !== 'failed'){
        return true;
      }
      return false;
    },
    failed_status(){
      const {file} = this;
      const {status} = file;
      if(status === 'failed'){
        return true;
      }
      return false;
    },
    select(){
      const {file} = this;
      return file.select;
    },
    download(){
      if(this.system){
        return false;
      }
      return true;
    }
    
  },
  methods: {
    memory(size){
      if(size> 1024){
        return (size / 1024.00).toFixed(2) + 'M';
      }else{
        return size + 'K';
      }
    },
    selectFile(){
      this.$emit('handleSelect', this.file);
    },
    // 触发预览
    imagePreview(){
      this.$refs.image.clickHandler();
      this.setImgZindex();
      // this.z_index = this.$utils.getZIndex();
    },
    setImgZindex(){
      const zIndex = this.$utils.getZIndex();
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex;
      })
    },
    deleteFile(){
      this.$emit('handleDelete', this.file);
    },
    downloadFile(){
      this.$emit('handleDownload', this.file);
    },

  }

}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_part_picture_page{
  width: 200px;
  margin: 5px;
  &:hover, &.uploading, &.select{
    .mask{
      opacity: 1;
    }
  }
  .img_group{
    position: relative;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
    .image{
      width: 100%;
    }
  }
  .mask{
    @include transition;
    opacity: 0;
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
  }
  .info_group{
    width: 100%;
    height: 100%;
    overflow: hidden;
    .select{
      @include background(16px, 16px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/task/picture_select_button.png);
      margin: {
        left: 6px;
        top: 6px;
      }
      &.on{
        background-image: url(~@/assets/images/task/picture_select_button_on.png);
      }
    }
    .fun_group{
      width: 100%;
      padding: {
        left: 6px;
        bottom: 6px;
        right: 6px;
      }
      .info_status{

      }
      .failed{
        color: #D82E0D;
        font-size: 14px;
      }
      .fun_box{
        .button{
          @include background(24px, 22px);
          width: 24px;
          height: 24px;
          margin-top: 8px;
          &:first-child{
            margin-top: 0px;
          }
          &.delete{
            background-image: url(~@/assets/images/task/picture_delete_button.png);
          }
          &.download{
            background-image: url(~@/assets/images/task/picture_download_button.png);
          }
        }
      }
    }
  }
  .upload_group{
    position: relative;
    width:100%;
    height: 100%;
    .uploading{
      .icon{
        width: 28px;
        height: 25px;
        background-image: url(~@/assets/images/task/picture_uploading.png);
      }
      .progress{
        padding-top: 8px;
        font-size: 14px;
      }
    }
    .cancal{
      position: absolute;
      z-index: 3;
      bottom: 10px;
      right: 10px;
    }
  }
  .name{
    height: 30px;
    background: #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    font-size: 12px;
    color: #333333;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    width: 100%;
  }
}
</style>