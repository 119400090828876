<template>
  <div
    class="components_task_main_widget_content_hyperlink_page Bbox"
  >
    <template
      v-for="val in list"
    >
      <div class="val d-flex align-center"
        :key="val.index"
      >
        <input type="text" class="name bbox"
          :class="{disabled: !editable}"
          :disabled="!editable"
          :readonly="other_lock"
          placeholder="请输入链接说明"
          v-model="val.name"
          @input="changeContent();"
          @click.stop="$emit('handleLock', item)"
          @select.stop="$emit('handleLock', item)"
        >
        <div class="url_group bbox"
          :class="{show: val.show}"
        >
          <input type="text" class="url bbox"
            :class="{edit: val.edit,on:val.url}"
            placeholder="请输入链接地址"
            v-model="val.url"
            @click="touchUrl(val)"
            :ref="`url_${val.index}`"
            @keyup.enter="doneEdit(val)"
            :disabled="!val.edit&&val.url!=''"
            @blur="doneEdit(val)"
          >
          <div class="done cp"
            v-if="val.edit"
            @click="doneEdit(val)"
          >完成</div>
          <div class="edit_group d-flex align-center justify-space-between"
            v-if="val.url!=''&&!val.edit"
          >
            <div
              class="d-flex align-center big"
            >
              <div class="button go"
                @click="goToUrl(val.url)"
              >点击前往</div>
              
            </div>
            <div
              class="d-flex align-center"
            >
              <div class="button"
                @click="startEdit(val)"
                v-if="editable"
              >编辑</div>
              <div class="button"
                v-if="0"
                @click="copyUrl(val.url)"
              >复制</div>
              <div class="button"
                @click="deleteItem(val);"
                v-if="editable"
              >删除</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    p_task_detail:{
      type: Object,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  data(){
    return {
      list: [],
      index: 0,
    }
  },
  computed:{
    

  },
  watch:{
    // 'item.repeal_content': {
    //   handler: function(val){
    //     if(val){
    //       this.init();
    //       this.$set(this.item, 'repeal_content', false);
    //     }
    //   },
    //   deep: true,
    // }
  },
  methods:{
    async getLock(){
      const {self_lock} = this;
      if(self_lock){
        return true;
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {p_task_detail} = this;
        const {id} = p_task_detail;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(p_task_detail);
            if(!res){
              return false;
            }
          }
        }
        return true;
       
      }
    },
    touchAdd(){
      this.list.push({
        name:'',
        url:'',
        index: this.index++,
      })
    },
    goToUrl(url){
      if(url === ''){
        return;
      }
      let link = '';
      if(url.indexOf('http://') == -1 && url.indexOf('https://') == -1){
        link = `http://${url}`;
      }else{
        link = url;
      }
      window.open(link, '_blank');
    },
    async startEdit(val){
      const lock = await this.getLock();
      if(!lock){
        return;
      }
      this.$set(val, 'edit', true);
      const ref_name = `url_${val.index}`;
      this.$nextTick(function(){
        this.$refs[ref_name][0].focus();
      })
    },
    copyUrl(){

    },
    async deleteItem(val){
      const lock = await this.getLock();
      if(!lock){
        return;
      }
      this.$openConfirm({
        title: '确定要删除链接',
        desc: '',
        sure:'',
        cancel:'',
        onSure:()=>{
          //标记控件修改
          this.$api.moduleTask.signWidgetModified(this.p_task_detail);
          const index = this.list.findIndex(unit=>{
            return unit.index === val.index;
          })
          if(index !== -1){
            this.list.splice(index, 1);
            this.changeContent();
          }
          this.modifiedSign();
        },
        onCancel:()=>{},
      })
    },
    touchUrl(val){
      if(val.url ==''){
        this.$set(val,'edit', true);
      }
    },
    doneEdit(val){
      this.$set(val, 'url', val.url.trim());
      this.$set(val, 'edit', false);
      this.changeContent();
      this.modifiedSign();
      // 强制显示500ms 之后显示操作栏
      this.$set(val, 'show', true);
      setTimeout(()=>{
        this.$set(val, 'show', false)
      }, 500)
    },
    changeContent(){
      const {list} = this;
      if(!list || list.length == 0){
        // this.$set(this.p_task_detail, 'content', '');
        this.p_task_detail.content = ''
        return;
      }
      let content = '';
      list.forEach(unit=>{
        if(content != ''){
          content += '||'
        }
        content += unit.name;
        content += ',,';
        content += unit.url;

      })
      // this.$set(this.item, 'content', content);
      this.p_task_detail.content = content
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.item);

    },
    init(){
      const {p_task_detail} = this
      const {content} = p_task_detail
      if(content){
        let list_arr = content.split('||');
        if(list_arr&&list_arr.length){
          let list = [];
          list_arr.forEach(unit=> {
            const key_arr = unit.split(',,');
            if(key_arr){
              list.push({
                name: key_arr[0],
                url: key_arr[1],
                index: this.index++,
              })
            }
          })
          this.$set(this, 'list', list);
        }
      }else{
        this.$set(this, 'list', []);
      }
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.p_task_detail, parent: this.parent});
    },
  },
  mounted(){
    this.init();
    // const {item} = this;
    // const {content} = item;
    // if(content){
    //   let list_arr = content.split('||');
    //   // console.log(list_arr, 'list_arr');
    //   if(list_arr&&list_arr.length){
    //     let list = [];
    //     list_arr.forEach(unit=> {
    //       const key_arr = unit.split(',,');
    //       if(key_arr){
    //         list.push({
    //           name: key_arr[0],
    //           url: key_arr[1],
    //         })
    //       }
    //     })
    //     this.$set(this, 'list', list);
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.components_task_main_widget_content_hyperlink_page{
  padding: 0 10px;
  .item{
    width: 100%;
    padding: 10px 0;
  }
  input{
    @include placeholder(#ccc);
    border:none;
    outline: none;
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    height: 24px;
    background-color: transparent;
  }
  .name{
    width: 160px;
  }
  .url_group{
    position: relative;
    z-index: 2;
    border-left: 1px solid #f4f4f4;
    flex: 1;
    margin: 5px 0;
    &:hover{
      .edit_group{
        opacity: 1;
        transform: scaleY(1);
      }
    }
    &.show{
      &:hover{
        .edit_group{
          opacity: 0;
          transform: scaleY(0);
        }
      }
    }
  }
  .url{
    position: relative;
    z-index: 2;
    width: 100%;
    padding: {
      left: 10px;
      right: 50px;
    }
    color: #333;
    
    &.edit{
      background-color: #eee;
      color: #333;
      text-decoration: unset;
      &.on{
        background-color: #eee;
        color: #333;
        text-decoration: unset;
      }
    }
    &.on{
      text-decoration: underline;
      color: $main_blue;
    }

  }
  .done{
    position: absolute;
    z-index: 3;
    top:0;
    right: 0;
    background-color: $main_blue;
    font-size: 14px;
    text-align: center;
    color: #fff;
    line-height: 24px;
    width: 50px;
  }
  .edit_group{
    @include transition;
    transition-delay: 0.5s;
    transform-origin: top left;
    z-index: 3;
    transform: scaleY(0);
    opacity: 0;
    position: absolute;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    .big{
      // flex: 1;
    }
    .button{
      cursor: pointer;
      padding: 0 16px;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 24px;
      border-right: 1px solid #fff;
      text-align: center;
      &.go{
        // width: 100%;
        padding: 0 100px;
      }
      &:last-child{
        border-right: none;
      }
    }
  }
}
</style>