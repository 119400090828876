var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-time-picker", {
    ref: "time",
    staticClass:
      "components_task_main_widget_content_part_time_for_time_page cp",
    attrs: {
      editable: false,
      clearable: _vm.editable && !_vm.other_lock,
      placeholder: "选择时间",
      "popper-class":
        "components_task_main_widget_content_part_time_for_time_pagepopover",
      "picker-options": {
        format: "HH:mm",
      },
      format: "HH:mm",
      disabled: !_vm.editable,
      "default-value": new Date(),
      readonly: _vm.other_lock,
    },
    on: {
      change: function ($event) {
        return _vm.changeTime()
      },
    },
    nativeOn: {
      click: function ($event) {
        $event.stopPropagation()
        return _vm.handleClick()
      },
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v
      },
      expression: "time",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }