<template>
  <div
    class="components_task_main_widget_title_page d-flex align-center justify-space-between bbox"
    v-if="p_task_detail"
  >
    <div class="title_group d-flex align-center justify-space-between bbox">
      <div class="info_group d-flex align-center" >
        <div class="icon"
          :class="[getWidgetType(p_task_detail.type)]"
        ></div>
        <input
          type="text"
          v-model="p_task_detail.remark"
          class="title"
          :class="{disabled: 0}"
          placeholder="输入标题"
          @select.stop="handleSelect()"
          @click.stop="handleClick()"
        >
      </div>
      <div class="other_group flex-shrink-0 d-flex align-center"
        :class="{uneditable:1||!editable}"
      >
        <div class="button download"
          v-if="downloadable"
          @click="multiDownload();"
        ></div>
        <div class="button delete"
          v-if="deleteable  && !other_lock"
          @click="multiDelete();"
        ></div>
        <div class="button sort"
          v-if="sortable  && !other_lock"
          @click="touchSort()"
        ></div>
        <div class="button add"
          v-if="addable"
          @click="touchAdd();"
        ></div>
        <div class="button select"
          :class="{on: is_all}"
          v-if="select"
          @click="touchSelectALL();"
        ></div>
      </div>
    </div>
    <fun-group
      v-if="0"
      :item="item"
      :relevancy="relevancy"
      :public_notice="public_notice"
      :system="system"
      @handleDelete="doDelete"
      @handleDeleteRelevancy="deleteRelevancy"
      @handleSwitchReminder="handleSwitchReminder"
    ></fun-group>
    <file-sorting
      v-if="0"
    ></file-sorting>
  </div>
</template>

<script>
import FunGroup from './Title/FunGroup.vue';
import FileSorting from '@/components/PopUp/FileSorting/index.vue';
const widget_arr = {
  1: 'text',
  2: 'number',
  3: 'money',
  4: 'time',
  6: 'file',
  7: 'hyperlink',
  9: 'picture',
  10: 'contact',
  11: 'wisdom',
}
export default {
  components:{
    FunGroup,
    FileSorting,
  },
  data(){
    return {
      remark: '',
      switch_file_sorting: false,
    }
  },
  props: {
    p_task_detail: {
      type: Object,
      default: undefined,
    }
  },
  computed:{
    addable(){ // 是否有添加按钮
      const {p_task_detail} = this
      const {type} = p_task_detail
      if(type === 6 || type ===9 || type == 7 || type == 10){
        return true
      }
      return false
    },
    other_group(){
      const {p_task_detail,shopping_mail} = this;
      const {type} = p_task_detail;
      if(shopping_mail){
        return false;
      }
      if(type === 6 || type ===9 || type == 7 || type == 10){
        return true;
      }
      return false;
    },
    select(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      if(fileList && fileList.length){
        return true;
      }
      return false;
    },
    deleteable(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      let deleteable = false;
      if(!fileList){
        return false;
      }
      fileList.forEach(unit=>{
        if(unit.select){
          deleteable = true;
        }
      })
      return deleteable;
    },
    sortable(){
      // return true;
      // let sortable = false;
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      if(!fileList){
        return false;
      }
      if(!fileList.length){
        return false;
      }
      let useful_list = [];
      fileList.forEach(item=> {
        if(item.type !== 1){
          useful_list.push(item);
        }
      })
      if(!useful_list.length || useful_list.length<2){
        return false;
      }
      return true;
    },
    downloadable(){
      const {p_task_detail, system} = this;
      if(system){
        return false;
      }
      const {fileList} = p_task_detail;
      let downloadable = false;
      if(!fileList){
        return false;
      }
      fileList.forEach(unit=>{
        if(unit.select){
          downloadable = true;
        }
      })
      return downloadable;
    },
    is_all(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      let is_all = true;
      fileList.forEach(unit=>{
        if(!unit.select){
          is_all = false;
        }
      })
      return is_all;
    },
    readonly_for_wisdom(){
      const user_id = Number(this.$tools.getUserId());
      const {p_task_detail} = this;
      const {type, id, userId} = p_task_detail;
     
      if(type == 11){
        if(userId){
          return !(Number(userId) === user_id);
        }
      }
      return false;
    }
  },
  watch:{
    // 'item.remark': {
    //   handler: function(val){
    //     this.init();
    //   },
    //   deep: true,
    // },
    'item.repeal_title': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.item, 'repeal_title', false);
        }
      },
      deep: true,
    },
    'item.cf_edit_data': {
      handler: function(val){
        if(val === undefined){
          this.init();
          
        }
      },
      deep: true,
    }
  },
  methods: {
    handleSelect(){
      this.handleLock()
    },
    handleClick(){
      this.handleLock()
    },
    handleLock(){
      this.p_task_detail.getLock()
    },
    // 旧
    getWidgetType(type){
      if(widget_arr[type]){
        return widget_arr[type]
      }
      return 'text';
    },
    changeRemark(){
      this.$set(this.p_task_detail, 'remark', this.remark);
      this.$api.moduleTask.signWidgetModified(this.p_task_detail);
      this.modifiedSign();
    },
    async touchAdd(){
      const {self_lock} = this;
      if(self_lock){
        this.$emit('handleAdd');
      }else{
        // const {task_config} = this.$store.state;
        // const {edit} = task_config;
        // const {p_task_detail} = this;
        // const {id} = p_task_detail;
        // if(edit){
        //   if(id){
        //     const res = await this.$api.moduleTask.getLock(p_task_detail);
        //     if(!res){
        //       return;
        //     }
        //   }
        // }
        this.$emit('handleAdd');
      }
    },
    async touchSort(){
      const {self_lock, p_task_detail} = this;
      const {uploading} = p_task_detail;
      if(uploading){
        this.$notice({desc: '文件未全部上传完成，暂不可修改排序'});
        return;
      }
      if(self_lock){
        this.openFileSorting();
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {p_task_detail} = this;
        const {id} = p_task_detail;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(p_task_detail);
            if(!res){
              return;
            }
          }
        }
        this.openFileSorting();
      }
    },
    openFileSorting(){
      const params = {
        parent: this.parent,
        widget: this.p_task_detail,
      }
      this.$emit("handleSortFile", params);
      // this.switch_file_sorting = true;
    },
    closeFileSorting(){
      this.switch_file_sorting = false;
    },
    touchSelectALL(){
      const {is_all} = this;
      // console.log(is_all)
      if(is_all){
        this.$emit('handleCancelAll');
      }else{
        this.$emit('handleSelectAll');
      }
    },
    multiDelete(){
      this.$emit('handleMultiDelete');
    },
    multiDownload(){
      this.$emit('handleMultiDownload');
    },
    init(){
      const {p_task_detail} = this;
      const {cf_edit_data} = p_task_detail;
      this.remark = p_task_detail.remark;
      if(cf_edit_data){
        if(cf_edit_data.remark){
          this.remark = cf_edit_data;
        }
      }
    },
    doDelete(data){
      this.$emit('handleDelete', {widget:data, parent:this.parent});
    },
    deleteRelevancy(data){
      this.$emit('handleDeleteRelevancy', data);
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.p_task_detail, parent: this.parent});
    },
    handleSwitchReminder(data){
      this.$emit('handleSwitchReminder', data);
    },
    touchTitle(){
      const {projection_screen_start, p_task_detail} = this;
      const {userId, type} = p_task_detail;
      const user_id = Number(this.$tools.getUserId());
      if(type == 11){
        if(userId && Number(userId) !== user_id){
          this.$notice({
            desc: '该控件只有创建者可以编辑',
          })
          return;
        }
      }
      if(projection_screen_start){
        this.$notice({
          desc: '正在投屏中,请先结束投屏',
        })
        return;
      }
      this.$emit('handleLock', this.p_task_detail);
    }
  },
  mounted(){
    // console.log(this.p_task_detail)
    // this.init();
  }
}
</script>
<style lang="scss" scoped>
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
@mixin button_icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}_button.png);
  }
}
.components_task_main_widget_title_page{
  width: 100%;
  .title_group{
    flex: 1;
    height: 47px;
    border-bottom: 2px solid #f4f4f4;
    padding: {
      left: 10px;
    }
    .info_group{
      flex: 1;
      .icon{
        @include background(18px, 18px);
        width: 20px;
        height: 20px;
        margin-right: 10px;
        @include icon(text);
        @include icon(number);
        @include icon(money);
        @include icon(time);
        @include icon(file);
        @include icon(hyperlink);
        @include icon(picture);
        @include icon(contact);
        @include icon(wisdom);
      }
      .title{
        @include placeholder(#ccc);
        flex: 1;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: bold;
        color: #333;
      }
    }
    .other_group{
      &.uneditable{
        padding-right: 30px;
      }
      .button{
        @include background(16px,16px);
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        @include button_icon(download);
        @include button_icon(delete);
        @include button_icon(add);
        @include button_icon(select);
        @include button_icon(sort);
        &:first-child{
          margin-left: 10px;
        }
        &.select.on{
          background-image: url(~@/assets/images/task/select_button_on.png);
        }      
      }
    }

  }
  
}
</style>