<template>
  <div class="components_task_main_widget_title_fun_group_page fun_group flex-shrink-0">
    <el-popover
      v-model="switch_menu"
      placement="bottom-end"
      trigger="click"
      :visible-arrow="false"
      popper-class="components_task_main_widget_title_fun_group_page_menu"
    >
      <div class="menu_group">
        <template
          v-if="relevancy_status && delete_right"
        >
          <div class="button"
            v-if="can_relevancy && !item.islink"
            @click="openRelevancy();"
          >关联</div>
          <div class="button"
            v-if="can_relevancy && item.islink"
            @click="deleteRelevancy();"
          >删除关联</div>
        </template>
        <div class="button"
          v-if="delete_right"
          @click="doDelete()"
        >删除</div>
        <div class="button"
          v-if="reminder_status"
          @click="setReminder()"
        >设置提醒</div>
      </div>
      <template slot="reference">
        <div class="menu button"
          @click="handleClick();"></div>
      </template>
    </el-popover>
  </div>    
</template>
<script>
export default {
  data(){
    return{
      switch_menu: false,
    }
  },
  props:{
    item: {
      type: Object,
      default: null,
    },
    relevancy: {
      type: Boolean,
      default: false,
    },
    public_notice: {
      type: Boolean,
      default: null,
    },
    system: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * 能否关联 , 已经是关联则不能关联
     */
    can_relevancy(){
      const {relevancy} = this;
      if(relevancy){
        return false;
      }
      return true;
    },
    relevancy_status(){
      const {public_notice} = this;
      if(public_notice){
        return false;
      }
      return true;
    },
    reminder_status(){
      const {task_config} = this.$store.state;
      const {relevancy, public_notice, system} = this;
      const {task_type, draft} = task_config;
      if(system){
        return false;
      }
      if(task_type == 2){
        return false;
      }
      if(draft){
        return false;
      }
      if(public_notice){
        return false;
      }
      if(relevancy){
        return false;
      }
      return true;
    },
    delete_right(){
      const {item} = this;
      const {userId, type, id} = item;
      const user_id = Number(this.$tools.getUserId());
      if(type == 11 && id && user_id !== userId){
        return false;
      }
      return true;
    }
  },
  methods: {
    openRelevancy(){
      this.$set(this.item, 'islink', true);
      this.$set(this.item, 'switch_relevancy', true);
      this.$set(this.item, 'controlLinkList', []);

      this.switch_menu = false;
    },
    deleteRelevancy(){
      this.$emit('handleDeleteRelevancy', this.item);
      this.switch_menu = false;
    },
    async handleClick(){
      this.setIndex();
      const {task_config} = this.$store.state;
      const {edit} = task_config;
      const {item} = this;
      const {id} = item;
      if(edit){
        if(id){
          const res = await this.$api.moduleTask.getLock(item);
          if(!res){
            this.switch_menu = false;
          }
        }
      }
    },
    setIndex(){
      this.$utils.setPopoverZIndex('.components_task_main_widget_title_fun_group_page_menu');
    },
    doDelete(){
      this.$emit('handleDelete', this.item);
    },
    setReminder(){
      this.switch_menu = false;
      this.$emit('handleSwitchReminder', this.item);
    },
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_title_fun_group_page.fun_group{
  height: 47px;
  .menu{
    @include transition;
    @include background(5px, 20px);
    width: 30px;
    height: 100%;
    background-image: url(~@/assets/images/task/title_menu.png);
    border-top-right-radius: 4px;
    &:hover{
      background-color: #eee;
    }
  }
}


</style>
<style lang="scss">
.components_task_main_widget_title_fun_group_page_menu{
  padding: 5px 0;
  margin-top: 0px!important;
  .menu_group{
    .button{
      @include transition;
      @include bbox;
      padding-left: 16px;
      background-color:#fff;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      &:hover{
        background-color: #eee;
      }
    }
  }
}
</style>