<template>
  <div
    class="components_task_main_widget_expand_bar_page bbox d-flex align-center"
  >
    <div class="reminder_group d-flex align-center cp"
      @click="openReminder();"
    >
      <img src="~@/assets/images/task/reminder_icon.png" alt="" class="icon">
      <div class="details_group"
        v-if="details_group"
      >{{reminder_details}}</div>
      <div class="sketch"
        v-if="reminder_sketch"
      >{{remindList.length}}个提醒</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    remindList: {
      type: Array,
      default: null,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    reminder_sketch(){
      const {remindList} = this;
      if(remindList.length > 1){
        return true;
      }
      return false;
    },
    details_group(){
      const {remindList} = this;
      if(remindList.length == 1){
        return true;
      }
      return false;
    },
    reminder_details(){
      const {remindList, details_group} = this;
      if(details_group){
        let details = '';
        const remind = remindList[0];
        
        // if(remind.ptype == 1){
        details += this.$api.reminder.showTime(remind);
        // }
        details += ` 提醒 `;

        const user_list = this.$api.reminder.getUserList(remind);
        user_list.forEach((unit,index)=> {
          const name = unit.chatUserName || unit.friendRemark || unit.userName;
          details += index ? `、${name}` : name;
        })
        return details;
      }
      return '';
    }
  },
  methods: {
    openReminder(){
      const { recycle } = this;
      if(recycle){
        return;
      }
      this.$emit('handleOpenReminder');
    }
  }
}
</script>

<style lang="scss" scoped>
.components_task_main_widget_expand_bar_page{
  width: 100%;
  height: 34px;
  background: #F8F9FB;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #F0F0F0;
  border-top: 0;
  margin-top: -4px;
  position: relative;
  z-index: 2;
  padding: 0 10px;
  .reminder_group{
    background-color: #F8E9E2;
    padding: 0 8px;
    height: 18px;
    border-radius: 2px;
    font-size: 12px;
    font-size: 12px;
    color: #CE5100;
    line-height: 18px;
    .icon{
      margin-right: 8px;
    }
  }
}
</style>