<template>
  <div 
    class="components_task_main_widget_content_time_page d-flex align-center bbox"
  >
    <time-type
      @handleChange="changeTimeType"
      :timetype="p_task_detail.timetype"
      :editable="editable"
      :other_lock="other_lock"
      :p_task_detail="p_task_detail"
    ></time-type>
    <time-for-date
      v-if="p_task_detail.timetype != 3"
      :content="String(p_task_detail.content)"
      @handleChange="changeDate"
      :editable="editable"
      :other_lock="other_lock"
      :p_task_detail="p_task_detail"
    ></time-for-date>
    <time-for-time
      v-if="p_task_detail.timetype != 2"
      :content="String(p_task_detail.content)"
      @handleChange="changeTime"
      :editable="editable"
      :other_lock="other_lock"
      :p_task_detail="p_task_detail"
    ></time-for-time>
  </div>
</template>
<script>
import TimeType from './Part/TimeType.vue';
import TimeForDate from './Part/TimeForDate.vue';
import TimeForTime from './Part/TimeForTime.vue';
export default {
  components: {
    TimeType,
    TimeForDate,
    TimeForTime,
  },
  props:{
    p_task_detail:{
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },

  },
  data(){
    return{
      date_data: '',
      time_data: '',
    }
  },
  methods: {
    changeTimeType(type){
      this.$set(this.item, 'timetype', type);
      this.$api.moduleTask.signWidgetModified(this.item);
      this.modifiedSign();
    },
    changeDate(date){
      this.$set(this, 'date_data', date);
      this.generateContent();
      this.modifiedSign();
    },
    changeTime(time){
      this.$set(this, 'time_data', time);
      this.generateContent();
      this.modifiedSign();
    },
    generateContent(){
      const { date_data, time_data} = this;
      const {year, month, date} = date_data || {};
      const {hour, minute} = time_data || {};
      const data = {
        year: year === undefined ? new Date().getFullYear() : year,
        month: month === undefined ? new Date().getMonth() : month,
        date: date === undefined ? new Date().getDate() : date,
        // hour: hour === undefined ? new Date().getHours() : hour,
        // minute: minute === undefined ? new Date().getMinutes() : minute,
        hour: hour === undefined ? 0 : hour,
        minute: minute === undefined ? 0 : minute,

      }
  
      // console.log(date_data, time_data);
      let content = new Date(data.year,data.month, data.date, data.hour, data.minute).getTime();
      if(year === undefined && month === undefined && date === undefined && hour === undefined && minute === undefined){
        content = '';
      }
      // this.$set(this.item, 'content', content);
      this.p_task_detail.content = content
      // this.$api.moduleTask.signWidgetModified(this.item);
      // this.modifiedSign();
    },
    // modifiedSign(){
    //   this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    // },

  },
  mounted(){
    const {p_task_detail} = this;
    const {content, timetype} = p_task_detail;
    // console.log(new Date(Number(content)));
    if(content){
      const time = new Date(Number(content));
      const year = time.getFullYear();
      const month = time.getMonth();
      const date = time.getDate();
      const hour = time.getHours();
      const minute = time.getMinutes();
      // console.log(year, month, date, hour, minute);
      this.$set(this,'date_data', {year, month, date});
      this.$set(this,'time_data', { hour, minute});
    }

  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_time_page{
  height: 47px;
  padding: 0 10px;
}
</style>
<style lang="css">
/* 时间选择样式修改  */
.el-date-table td span{
  border-radius: 0;
}
.el-input__icon {
  line-height: unset;
}
.el-input__inner{
  height: auto;
  cursor: pointer;
}
.el-input.is-disabled .el-input__inner{
  background-color: transparent;
  color: #333;
}

</style>