<template>
  <div
    class="components_task_main_widget_content_picture_page bbox"
    ref="container"
  >
    <input
      accept="image/*"
      type="file"
      ref="file"
      style="display:none;"
      multiple
      @change="fileChange"
    />
    <div class="waterfall_container"
      v-masonry
      transition-duration="0.3s"
      item-selector=".components_task_main_widget_content_part_picture_page"
      fit-width="true"
      id="masonry"
      v-if="switch_waterfall"
    >
      <div class="waterfall bbox"
        v-masonry-tile
      >
        <template
          v-for="val in p_task_detail.fileList"
        >
          <picture-part
            v-if="val.type != 1"
            :key="val.custom_index || val.id"
            :file="val"
            :width="width"
            :preview_list="preview_list"
            :editable="editable"
            :system="system"
            @handleDelete="deleteFile"
            @handleSelect="selectFile"
            @handleDownload="downloadFile"
          ></picture-part>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import PicturePart from './Part/Picture.vue';
export default {
  components: {
    PicturePart
  },
  props: {
    p_task_detail: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    system: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      list: [
        {url:'https://img-content.ourutec.com/pmcs/2021/08/02/1627872168777216325.png'},
        {url:'https://img-content.ourutec.com/pmcs/2021/08/02/1627872167735131424.png'},
        // {url:'https://img-content.ourutec.com/pmcs/2021/08/02/1627872168081644575.png'},
        // {url:'https://img-content.ourutec.com/pmcs/2021/08/02/1627872168313569683.png'},
        // {url:'https://img-content.ourutec.com/pmcs/2021/08/02/1627872168533674183.png'},
      ],
      container_width : 0,
      custom_index: 0,
      switch_waterfall: false,
    }
  },
  computed: {
    // 自由宽度图片的最大数量
    auto_width_picnum(){
      const {container_width} = this;
      if(container_width){
        return Math.floor(container_width / 210);
      }
      return 1;
    },
    // 每个单元的宽度
    width(){
      const { auto_width_picnum, container_width, p_task_detail} = this;
      const {fileList} = p_task_detail;
      if(fileList.length >= auto_width_picnum){
        return 200;
      }else{
        const width = Math.floor(Math.floor((container_width - 10) / fileList.length) - 16);
        // 只有一个的时候计算图片高度 大于 '' 时将 单元宽度减半
        if(fileList.length == 1){
          const file = fileList[0];
          const convert_height = (width * file.height) / file.width;
          if(convert_height > 800){
            return width/2;
          }
        }  
      
        return width;
      }
    },
    preview_list(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      const list = [];
      fileList.forEach(unit => {
        list.push(unit.url ? `${this.$img_url}${unit.url}` : unit.preview)
      })
      return list;
    },
    
  },
  watch:{
    'item.repeal_content': {
      handler: function(val){
        if(val){
          // this.init();
          setTimeout(()=>{

            this.$redrawVueMasonry(); // 重绘
          },200)
          this.$set(this.item, 'repeal_content', false);
        }
      },
      deep: true,
    }
  },
  methods: {
    touchAdd(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      if(fileList && fileList.length == 20){
        this.$notice({desc: '最多只能上传20张图片!'});
        return;
      }
      setTimeout(()=>{

        this.$refs.file.click();
        if (typeof this.$redrawVueMasonry === 'function') {
          this.$redrawVueMasonry("masonry")
        }
      },50)
      
    },
    async fileChange(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      const files = this.$refs.file.files;
      if(files && files.length && (fileList.length + files.length > 20)){
        this.$notice({desc: `最多只能上传20张图片!还可以上传${20-fileList.length}个`});
        this.$refs.file.value = ""; // 清空列表
        return;
      }
      // 最先加的放在最下面
      let upload_list = [];
      for(let i = 0; i<files.length; i ++){
        const file = files[i];
        let preivewData = await this.previewPicture(files[i]);
        const filedata = {
          filename: file.name,
          filesize: parseInt(Math.ceil(file.size / 1024)), // 向上取整
          filetype: this.$tools.getRealExtName(file.name),
          file,
          custom_index: this.custom_index++,
          width: preivewData.width,
          height: preivewData.height,
          preview: preivewData.url, // 预览地址
        }
        if(filedata.filesize >= 500*1024){
          this.$notice({desc: '无法上传超过500M的文件'});
        }else{
          upload_list.unshift(filedata);
        }
      }
      this.$set(this.p_task_detail, 'fileList', upload_list.concat(this.p_task_detail.fileList));
      this.$nextTick(function(){

        this.$redrawVueMasonry(); // 重绘
      })
      this.$refs.file.value = ""; // 清空图片列表
      this.autoUpload();
    },
    /**
     *  异步
     *  预览图片
     */
    previewPicture(item) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (event) => {
          /** 地址 */
          let url = event.target.result;
          var image = new Image();
          image.src = url;
          image.onload = () => {
            let { width, height } = image;
            // // console.log(image, image.width, image.height);
            resolve({ url, width, height });
          };
        };
      });
    },
     // 自动查找第一个可上传文件上传
    async autoUpload(){
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.p_task_detail);
      // this.$redrawVueMasonry("masonry"); // 重绘
      this.$set(this.p_task_detail, 'uploading', true); // 正再上传
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      const file = fileList.find(unit=>{
        return !unit.url && unit.status !== 'failed';
      })
      if(file !== undefined){
        let res = await this.upload(file);
        if(res){
          if( res === 'no_upload'){
                // this.$set(file, 'status', 'done');
            this.$set(file, 'url', file.preview);
            this.$set(file, 'createTime', new Date().getTime());
            this.$set(file, 'status', 'no_upload');
          }else{
          // this.$set(file, 'status', 'done');
            this.$set(file, 'url', res);
            this.$set(file, 'createTime', new Date().getTime());
            this.$set(file, 'status', '');
          }
        }else{
          this.$set(file, 'status', 'failed');
        }
        this.autoUpload();
        this.modifiedSign();
      }else{
        this.$set(this.p_task_detail, 'uploading', false); // 正再上传 结束
        // setTimeout(() => {
          
          // this.$redrawVueMasonry(); // 重绘
          // // console.log('redrawVueMasonry')
          
        // }, 3000);
        return;
      }

    },
    async upload(item){
      const {system} = this;
      if(system){
        return 'no_upload'
      }
      let res;
      const handleProgress = (progress)=>{
        this.$set(item, 'progress', Math.floor(progress*100));
      }
      const {file} = item;
      const {filetype} = item;
      res = await this.$api.oss.uploadPicture({
        file,
        handleProgress
      })
      // // console.log(res);
      if(res !== false){
        return res;
      }
      return false;
    },
    async getLock(){
      const {self_lock} = this;
      if(self_lock){
        return true;
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {p_task_detail} = this;
        const {id} = p_task_detail;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(p_task_detail);
            if(!res){
              return false;
            }
          }
        }
        return true;
       
      }
    },
    async deleteFile(file){
      const lock = await this.getLock();
      if(!lock){
        return;
      }
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.p_task_detail);
      const {id} = file;
      if(id){
        this.$set(file, 'type', 1);
      }else{
        const index = this.p_task_detail.fileList.findIndex(unit=>{
          return unit.custom_index == file.custom_index;
        })
        if(index != -1){
          this.p_task_detail.fileList.splice(index,1);
         
        }
      }
      this.$nextTick(function(){
        this.$redrawVueMasonry();
      })
      this.modifiedSign();
    },
    selectFile(file){
      const {select} = file;
      if(select){
        this.$set(file, 'select', false);
      }else{
        this.$set(file, 'select', true);
      }
    },
    async downloadFile(file){
      // this.$api.download.picture({file});
      let {id} = file;
      if(id){
        let exportURL = `https://${this.$domain_name}/project/download?fileid=${id}&type=1`;
        
        // iframe下载
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"; // 防止影响页面
        iframe.style.height = 0; // 防止影响页面
        iframe.src = exportURL; 
        document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
        // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
        setTimeout(()=>{
          iframe.remove();
        }, 5 * 60 * 1000);
      }
    },
    selectAll(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      fileList.forEach(unit=>{
        if(!unit.select){
          this.$set(unit, 'select', true);
        }
      })
    },
    cancelAll(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      fileList.forEach(unit=>{
        if(unit.select){
          this.$set(unit, 'select', false);
        }
      })
    },
    async multiDownload(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      for(let i in fileList){
        if(fileList[i].select){
          await this.downloadFile(fileList[i]);
        }
      }
    },
    async multiDelete(){
      const {p_task_detail} = this;
      const {fileList} = p_task_detail;
      const file = fileList.find(unit=>{
        return unit.select && unit.type != 1;
      })
      if(file !== undefined){
        await this.deleteFile(file);
        this.multiDelete();// 继续删除
      }
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.p_task_detail, parent: this.parent});
    },
  },
  mounted(){
    this.container_width = this.$refs.container.clientWidth;
    // 打开时重新渲染 防止拖动后瀑布流插件不生效
    this.switch_waterfall = false;
    setTimeout(()=>{
      this.switch_waterfall = true;
    }, 2)
    // setInterval(()=>{
    this.$redrawVueMasonry();
    // }, 3000)
  }

  /**
   * 
文件上传参数

"filename":"西瓜.png",
"filetype":"pic",
"filesize":9,
"width":144,
"height":144,
"url":"pmcs/2021/08/02/1627875860150151985.png",
"type":0,

   */
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_picture_page{
  width: 100%;
  // padding: 10px 5px;
  .waterfall_container{
    // height: auto!important;
  }
  .waterfall{
    width: 100%;
   
  }
}
</style>