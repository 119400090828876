<template>
  <div 
    class="components_task_main_widget_content_contact_page bbox"
    :class="{padding_none:!(p_task_detail.userList && p_task_detail.userList.length)}"
  > 
    <div class="list d-flex align-center flex-wrap">
      <template
        v-for="val in p_task_detail.userList"
      >
        <div
          :key="val.userId"
          class="user flex-shrink-0"
        >
          <div class="close cp"
            v-if="editable && !other_lock"
            @click="deleteUser(val);"
          ></div> 
          <el-tooltip 
            :content="val.friendRemark || val.userName"
            placement="bottom"
            :open-delay="500"
          >
            <img
              :src="`${$avatar_url}${val.thumbnail}@!small200`"
              alt=""
              class="avatar cp"
              @click="openUserDetail(val)"
            >
          </el-tooltip>
          <!-- <div class="name">{{}}</div> -->
        </div>
      </template>

    </div>
    <!-- <select-widget-contact
      v-if="switch_select_contact"
      @handleClose="handleClose"
      @handleSure="handleSure"
      :chat_user_list="chat_user_list"
      :friend_list="friend_list"
      @handleSelect="handleSelect"
      @handleRemove="handleRemove"
      :user_list="p_task_detail.userList"
      @setZIndex="setZIndex"
    ></select-widget-contact> -->
  </div>
</template>
<script>
// import SelectWidgetContact from '@/components/PopUp/SelectWidgetContact';
import store from '@/store'
export default {
  props: {
    p_task_detail: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    self_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  components: {
    // SelectWidgetContact,
  },
  data(){
    return {
      switch_select_contact: false,
      chatUserList: null,
      friendList: null,
    }
  },
  computed: {
    chat_user_list(){
      const {chatUserList, p_task_detail} = this;
      const {userList} = p_task_detail;
      //根据userList 同步修改已选择
      const list = this.$tools.deepClone(chatUserList);
      if(list&&list.length && userList && userList.length){

        list.forEach(unit=>{
          const isin = userList.find(val=>{
            return val.userId == unit.userId;
          })
          if(isin){
            unit.selected = true;
          }
        })
      }

      return list;
    },
    friend_list(){
      const {friendList, p_task_detail} = this;
      const {userList} = p_task_detail;
      //根据userList 同步修改已选择
      const list = this.$tools.deepClone(friendList);
      if(list&&list.length && userList && userList.length){
        list.forEach(unit=>{
          const isin = userList.find(val=>{
            return val.userId == unit.friendUserId;
          })
          if(isin){
            unit.selected = true;
          }
        })
      }
      return list;
    },
  },
  methods: {
    async touchAdd(){
      // 判断是否是工作组 如果是 获取工作组成员
      await this.getChatGroupList();
      // this.switch_select_contact = true; // 旧方法
      const {
        chat_user_list,
        friend_list,
        p_task_detail,
      } = this;
      const {
        userList: user_list,
      } = p_task_detail;
      this.$select_widget_contact({
        chat_user_list,
        friend_list,
        user_list,
        p_task_detail,
        cb: async (params) => {
          const {method_name, data} = params;
          // console.log(params)
          this[method_name](data);
        }
      });
    },
    handleClose(){
      this.switch_select_contact = false;
    },
    async getChatGroupList(){
      const {task_config} = this.$store.state;
      const {select_send_object, chatType, chatId} = task_config;
      if(select_send_object){
        if(chatType === 1 && chatId != null){
          // 获取指定的群组成员
          let res = await this.$http.request('/projectFriends/chatUserList', {chatId});
          this.$set(this, 'chatUserList', res.content.list);

        }else{
          this.$set(this, 'chatUserList', null);
        }
      }else{
        if(this.$tools.getChatType() == 1){
          this.$set(this, 'chatUserList', this.$store.state.dialog_user_list)
        }
      }
    },
    handleSelect(userid){
      const {chatUserList, friendList} = this;
      if(chatUserList != null){
        const aim = chatUserList.find(unit=>{
          return unit.userId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', true);
        }
      }
      if(friendList != null){
        const aim = friendList.find(unit=>{
          return unit.friendUserId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', true);
        }
      }
    },
    handleRemove(userid){
      const {chatUserList, friendList} = this;
      if(chatUserList != null){
        const aim = chatUserList.find(unit=>{
          return unit.userId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', false);
        }
      }
      if(friendList != null){
        const aim = friendList.find(unit=>{
          return unit.friendUserId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', false);
        }
      }
    },
    handleSure(selected_list){
      // this.$set(this.item, 'modified', true);
      this.$set(this.p_task_detail, 'userList', selected_list);
      this.$nextTick(function(){
        this.generateContent();
      })
    },
    generateContent(){
      const {userList} = this.p_task_detail;
      let content = '';
      if(userList){
        if(userList&&userList.length){
          userList.forEach(unit=>{
            content += content ? `,${unit.userId}`: unit.userId;
          })
        }
      }
      this.$set(this.p_task_detail, 'content', content);
      //标记控件修改
      this.$api.moduleTask.signWidgetModified(this.p_task_detail);
      this.modifiedSign();
    },
    async deleteUser(val){
      const {self_lock} = this;
      if(self_lock){
        this.doDelete(val);
      }else{
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {p_task_detail} = this;
        const {id} = p_task_detail;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(p_task_detail);
            if(!res){
              return;
            }
          }
        }
        this.doDelete(val);
      }
    },
    doDelete(val){
      const {userId} = val;
      const {p_task_detail} = this;
      const {userList} = p_task_detail;
      const index = userList.findIndex(unit=>{
        return unit.userId == userId;
      })
      if(index != -1){
        userList.splice(index, 1);
      }
      this.$nextTick(function(){
        this.generateContent();
      })
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.p_task_detail, parent: this.parent});
    },
    setZIndex(zindex){
      this.$emit('setZIndex', zindex);
    },
    openUserDetail(item){
      this.$user_detail({data: item});
      // console.log(item);
    }
  },
  async mounted(){
     // 如果好友不存在获取
    if(!store.state.friend_list){
      let res = await this.$http.request("/projectFriends/friendsList", {});
      if (res.contents.list.length != 0) {
        this.$set(this, 'friendList', res.contents.list);
      }
    }
  }
}

/**
旧版
name: mp
to_users: 4360
chat_type: 1
chat_id: 132
controls: [{"type":10,"remark":"","content":"4378,4379","fileJson":[],"controlLinkJson":[],"sort":1}]
task_type: 1
hide: 0
colour: 

新版
name: mp5
to_users: 4360
chat_type: 1
chat_id: 132
controls: [{"type":10,"remark":"","content":"4378,4379","controlLinkList":[],"sort":1}]
task_type: 1
hide: 0
colour: 
addtags: 
 */
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_contact_page{
  padding: 7px 10px;
  &.padding_none{
    padding: 0;
  }
  .list{
    .user{
      position: relative;
      margin-right: 10px;
      &:hover{
        .close{
          opacity: 1;
        }
      }
      .avatar{
        width: 34px;
        height: 34px;
        border-radius: 4px;
        position: relative;
      }
      .close{
        @include transition;
        @include background;
        position: absolute;
        opacity: 0;
        width: 16px;
        height: 16px;
        z-index: 3;
        top:-8px;
        right: -8px;
        background-image: url(~@/assets/images/task/close_tag.png);
      }
      .name{
        position: absolute;
        color: #fff;
        font-size: 12px;
        background-color: #232D37;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 30px;
        white-space: nowrap;
        padding: 0 10px;
      }
    }
  }
}
</style>
