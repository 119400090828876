<template>
  <el-time-picker
    v-model="time"
    :editable="false"
    :clearable="editable && !other_lock"
    placeholder="选择时间"
    class="components_task_main_widget_content_part_time_for_time_page cp"
    popper-class="components_task_main_widget_content_part_time_for_time_pagepopover"
    :picker-options="{
      format: 'HH:mm'
    }"
    ref="time"
    format="HH:mm"
    @change="changeTime();"
    :disabled="!editable"
    :default-value="new Date()"
    :readonly="other_lock"
    @click.native.stop="handleClick();"
  >
  </el-time-picker>
</template>
<script>
export default {
  props: {
    p_task_detail: {
      type: Object,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      time: '',
    }
  },
  watch:{
    'p_task_detail.repeal_title': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.p_task_detail, 'repeal_title', false);
        }
      },
      deep: true,
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
      if(this.content != ''){
        this.time = Number(this.content);
      }
    },
    async changeTime(){
      // console.log(this.time);
      if(this.time == null){
        const {task_config} = this.$store.state;
        const {edit} = task_config;
        const {p_task_detail} = this;
        const {id} = p_task_detail;
        if(edit){
          if(id){
            const res = await this.$api.moduleTask.getLock(p_task_detail);
            if(!res){
              this.init();
              return;
            }
          }
        }

        this.$emit('handleChange', {hour:undefined,minute:undefined});
        this.time=null;
        return;
      }
      
      const time = new Date(this.time);
      const hour = time.getHours();
      const minute = time.getMinutes();
      this.$emit('handleChange', {hour,minute});
    },
    async handleClick(){
      // const {task_config} = this.$store.state;
      // const {edit} = task_config;
      // const {item} = this;
      // const {id} = item;
      // if(edit){
      //   if(id){
      //     const res = await this.$api.moduleTask.getLock(item);
      //     if(!res){
      //       this.$refs.time.handleClose();
      //       return;
      //     }
      //   }
      // }
      this.p_task_detail.getLock()
      this.$utils.setPopoverZIndex('.components_task_main_widget_content_part_time_for_time_pagepopover');
      // this.$emit('handleLock', this.item);
    }
  }
}
</script>
<style lang="scss">
.components_task_main_widget_content_part_time_for_time_page{
  width: 160px!important;
  border: none;
  .el-input__inner{
    border: none;
    background-color: transparent;
  }
}
</style>
<style lang="css">
/* 时间选择样式修改  */
.el-date-table td span{
  border-radius: 0;
}
.el-input__icon {
  line-height: unset;
}
.el-input__inner{
  height: auto;
  cursor: pointer;
}
.el-input.is-disabled .el-input__inner{
  background-color: transparent;
}

</style>

