<template>
  <div
    class="components_task_main_widget_content_text_page"
  > 
   <!-- :autosize="{minRows: 1, maxRows: 15}" -->
    <el-input
      type="textarea"
      :autosize="true"
      placeholder="请输入文本内容"
      v-model="p_task_detail.content"
      class="text"
      :class="{disabled: !editable}"
      :disabled="!editable"
      :readonly="other_lock"
      resize="none"
      @input="changeInput"
      @click.native.stop="p_task_detail.getLock()"
      @select.native.stop="p_task_detail.getLock()"
    ></el-input>
  </div>
</template>
<script>
export default {
  props:{
    p_task_detail:{
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  // watch:{
  //   'item.repeal_content': {
  //     handler: function(val){
  //       if(val){
  //         this.init();
  //         this.$set(this.item, 'repeal_content', false);
  //       }
  //     },
  //     deep: true,
  //   }
  // },
  data(){
    return {
      text: ''
    }
  },
  methods:{
    changeInput(){ 
      // this.$set(this.item, 'content', this.text);
      // this.$api.moduleTask.signWidgetModified(this.item);
      // this.modifiedSign();
    },
    // init(){
    //   const {item} = this;
    //   this.text=item.content;
    // },
    // modifiedSign(){
    //   this.$emit('handleMofifiedSign', {item: this.item, parent: this.parent});
    // },
  },
  mounted(){
    // this.init();
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_text_page{
  .text{
    font-size: 14px;
    line-height: 20px;
  }
}

</style>
<style>
.components_task_main_widget_content_text_page .el-textarea__inner{
  border:none!important;
  font-size: 14px;
  line-height: 20px;
  font-family: 'PingFang SC','MicroSoft YaHei';
  padding: 13px 10px;
  background-color: transparent;
}

.components_task_main_widget_content_text_page .el-textarea__inner::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled::-webkit-input-placeholder {
   /* WebKit browsers */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea__inner.is-disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.components_task_main_widget_content_text_page .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  color:#333;
}
</style>