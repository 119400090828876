<template>
  <el-popover
    placement="bottom-start"
    trigger="click"
    :visible-arrow="false"
    popper-class="components_task_main_widget_content_part_monetary_unit_page_popover"
    v-model="switch_popover"
    :disabled="!editable || other_lock"
    :class="{disabled: !editable || other_lock}"
    
  >
    <div class="list">
      <div class="item bbox cp"
        :class="{active: p_task_detail.unit === '元'}"
        @click="selectUnit('元')"
      >元</div>
      <div class="item bbox cp"
        :class="{active: p_task_detail.unit === '美元'}"
         @click="selectUnit('美元')"
      >美元</div>
    </div>
    <template slot="reference">
      <div
        class="components_task_main_widget_content_part_monetary_unit_page bbox cp d-flex align-center un_sel"
        :class="{disabled: 0 && !editable}"
        @click="handleClick();"
      >
        <div class="text">{{p_task_detail.unit}}</div>
        <div class="icon"></div>
      </div>
    </template>
  </el-popover>
</template>
<script>
export default {
  props:{
    p_task_detail: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: '元',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    other_lock: {
      type: Boolean,
      default: false,
    },

  },
  data(){
    return{
      switch_popover: false,
    }
  },
  methods: {
    selectUnit(item){
      this.p_task_detail.unit = item
      // this.$emit('handleChange', item);
      this.switch_popover = false;
    },
    async handleClick(){
      // const {task_config} = this.$store.state;
      // const {edit} = task_config;
      // const {item} = this;
      // const {id} = item;
      // if(edit){
      //   if(id){
      //     const res = await this.$api.moduleTask.getLock(item);
      //     if(!res){
      //       this.switch_popover = false;
      //     }
      //   }
      // }
      // this.$emit('handleLock', this.item);
      const {p_task_detail} = this;
      const {id} = p_task_detail;
      if(id){
        const res = await this.p_task_detail.getLock()
        console.log(res)
        if(!res){
          // this.init();
          this.switch_popover = false
          return;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_part_monetary_unit_page{
  @include transition;
  font-size: 14px;
  color: #333333;
  height: 28px;
  border-radius: 4px;
  padding: 0 10px;
  &.disabled:hover{
    background-color: transparent;
  }
  &:hover{
    background-color: #eee;
    .icon{
      opacity: 1;
    }
  }
  .text{
    padding-right: 12px;
  }
  .icon{
    @include transition;
    opacity: 0;
    width: 9px;
    height: 6px;
    background-image: url(~@/assets/images/task/monetary_unit.png);
  }
}
</style>
<style lang="scss">
.components_task_main_widget_content_part_monetary_unit_page_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  .list{
    .item{
      @include transition;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      padding: 0 10px;
      background-color: #eee;
      &.active{
        font-weight: bold;
      }
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
</style>