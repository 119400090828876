var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_money_page bbox d-flex align-center",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.p_task_detail.content,
            expression: "p_task_detail.content",
          },
        ],
        staticClass: "money bbox",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "text",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          placeholder: "输入金额",
        },
        domProps: { value: _vm.p_task_detail.content },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.p_task_detail, "content", $event.target.value)
            },
            function ($event) {
              return _vm.changeContent()
            },
          ],
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleClick()
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.handleSelect()
          },
        },
      }),
      _c("monetary-unit", {
        staticClass: "bbox unit_group",
        attrs: {
          p_task_detail: _vm.p_task_detail,
          unit: _vm.p_task_detail.unit,
          editable: _vm.editable,
          other_lock: _vm.other_lock,
        },
        on: { handleChange: _vm.changeUnit, handleLock: _vm.handleLock },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }