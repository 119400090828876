var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_content_number_page bbox d-flex align-center",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.p_task_detail.content,
            expression: "p_task_detail.content",
          },
        ],
        staticClass: "number bbox",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "text",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          placeholder: "输入数字",
        },
        domProps: { value: _vm.p_task_detail.content },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.p_task_detail, "content", $event.target.value)
            },
            function ($event) {
              return _vm.changeContent()
            },
          ],
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.p_task_detail)
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.p_task_detail)
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.p_task_detail.unit,
            expression: "p_task_detail.unit",
          },
        ],
        staticClass: "unit bbox",
        class: { disabled: !_vm.editable },
        attrs: {
          type: "text",
          disabled: !_vm.editable,
          readonly: _vm.other_lock,
          placeholder: "输入单位",
        },
        domProps: { value: _vm.p_task_detail.unit },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.p_task_detail, "unit", $event.target.value)
            },
            function ($event) {
              return _vm.changeUnit()
            },
          ],
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.p_task_detail)
          },
          select: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleLock", _vm.p_task_detail)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }