<!--
 * @Author       : Hugo
 * @Date         : 2022-03-03 16:54:31
 * @LastEditTime : 2022-03-14 12:12:34
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Task/Main/Widget/Content/Wisdom.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_task_main_widget_content_wisdom_page bbox d-flex align-center justify-start"
  > 
    <!-- :autosize="{minRows: 1, maxRows: 15}" -->
    <div class="projection_screen_group separate un_sel flex-shrink-0"
      v-if="status_projection_screen && editable"
    >
      <el-popover
        placement="bottom-start"
        trigger="click"
        :visible-arrow="false"
        popper-class="components_task_main_widget_content_wisdom_page_projection_screen"
        v-model="switch_projection"
      >
        <div class="button btn"
          @click.stop="endForScreen();"
        >结束投屏</div>
        <template
          slot="reference"
        >
          <div class="projection_screen d-flex align-center justify-start cp">
            <img src="~@/assets/images/task/wisdom/projection_screen.png" alt="" 
              class="icon"
            >
            <div class="text ml-8">投屏中</div>
          </div>
        </template>
      </el-popover>
    </div>
    
    <div class="content_group d-flex align-center justify-start separate">
      <img src="~@/assets/images/task/wisdom/content.png" alt="" 
        class="icon mr-8"
      >
      <input
        type="text"
        class="content"
        placeholder="请输入投屏的任务描述"
        @input="p_task_detail.edit({key:'content', value: content})"
        v-model="content"
        @click.stop="getLock();"
        :readonly="!editable || status_projection_screen"
      >
    </div>
    <div class="time_group d-flex align-center justify-start separate">
      <img
        src="~@/assets/images/task/wisdom/time.png"
        alt=""
        class="icon mr-8"
      >
      <input
        type="text"
        class="days"
        maxlength="3"
        placeholder="n"
        @input="numerInput({type:'days'})"
        @click.stop="getLock();"
        :readonly="!editable || status_projection_screen"
        v-model="days"
      >
      <div class="text">天</div>
      <input
        type="text"
        maxlength="2"
        class="hours"
        placeholder="n"
        @input="numerInput({type:'hours'})"
        @click.stop="getLock();"
        :readonly="!editable || status_projection_screen"
        v-model="hours"
      >
      <div class="text">时</div>
      <input
        type="text"
        maxlength="2"
        class="minutes"
        placeholder="n"
        @input="numerInput({type:'minutes'})"
        @click.stop="getLock();"
        :readonly="!editable || status_projection_screen"
        v-model="minutes"
      >
      <div class="text">分</div>
    </div>
    <div class="select_group"
    >
      <el-popover
        placement="bottom-start"
        trigger="click"
        :visible-arrow="false"
        :disabled="!editable || status_projection_screen"
        v-model="switch_screen"
        popper-class="components_task_main_widget_content_wisdom_page_select_group"
      >
        <div class="list_group d-flex align-start justify-start flex-column un_sel"
        > 
          <template
            v-if="screen_list && screen_list.length"
          >
            <div class="all flex-shrink-0 d-flex align-center justify-start bbox cp"
              @click.stop="handleSelectAll();"
            >
              <div class="icon flex-shrink-0"
                :class="{active: selected_all}"
              ></div>
              <div class="text">全选</div>
            </div>
            <div class="list_box">
              <template
                v-for="(item,index) in screen_list"
              >
                <div class="unit d-flex align-center justify-start bbox cp"
                  :key="index"
                  @click.stop="toggleSelect(item);"
                >
                  <div class="icon flex-shrink-0"
                    :class="{active: item.cf_selected}"
                  ></div>
                  <div class="text">{{item.screenName}}</div>
                </div>
              </template>

            </div>
          </template>
          <div class="unit none"
            v-else
            @click.stop="switch_screen = false;"            
          >无可用设备</div>
         
        </div>
        <template
          slot="reference"
        >
          <div class="select_btn_group d-flex align-center justify-start cp"
            @click.stop="touchSelect()"
          >
            <img
              src="~@/assets/images/task/wisdom/select.png"
              alt=""
              class="icon mr-8"
            >
            <div class="text"
              :class="{active: screen_name !== ''}"
            >{{screen_name === ''? '选择投屏设备' : screen_name}}</div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
import {PScreen} from '@/types';
import store from '@/store'
export default {
  props:{
    p_task_detail:{
      type: Object,
      default: null
    },
    // editable: {
    //   type: Boolean,
    //   default: true,
    // },
    other_lock: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
    status_projection_screen: {
      type: Boolean,
      default: false,
    }
  },
  watch:{
    'item.repeal_content': {
      handler: function(val){
        if(val){
          this.init();
          this.$set(this.item, 'repeal_content', false);
        }
      },
      deep: true,
    },
    screen_list: {
      handler: function(val){
        if(val && val.length){
          const all = val.every(item=>{
            return item.cf_selected === true;
          })
          this.selected_all = all;
        }
      },
      deep: true,
    },
    countdown: function(val){
      this.p_task_detail.edit({
        key: 'countdown',
        value: val,
      })
    },
    screens: function(val){
      this.p_task_detail.edit({
        key: 'screens',
        value: val,
      })
    },
  },
  computed: {
    screen_name(){
      const {screen_list} = this;
      let screen_name = '';
      if(screen_list && screen_list.length){
        screen_list.forEach(item => {
          if(item.cf_selected){
            screen_name += `${screen_name === '' ? '':','}${item.screenName}`
          }
        })
      }
      return screen_name;
    },
    screens(){
      let screens = '';
      const {screen_list} = this;
      if(screen_list && screen_list.length){
        screen_list.forEach(item => {
          if(item.cf_selected){
            screens += `${screens === '' ? '':','}${item.screenNo}`
          }
        })
      }
      return screens;
    },
    // 倒计时 时间计算
    countdown(){
      let countdown = '';
      const {days, hours, minutes} = this;
      if(days !== '' || hours !== '' || minutes !== ''){
        countdown = 0;
        if(days){
          countdown += Number(days)*24*60;
        }
        if(hours){
          countdown += Number(hours)*60;
        }
        if(minutes){
          countdown += Number(minutes);
        }
      }
      return countdown;
    },
  },

  data(){
    return {
      content: '',
      days: '',
      hours: '',
      minutes: '',
      screen_list: undefined,
      selected_all: false,
      switch_screen: false,
      editable: true,  // 只有创建者可以编辑
      switch_projection: false, // 投屏状态
    }
  },
  methods:{
    numerInput(params={}){
      const {type} = params;
      // 只剩输入数字
      let str = String(this[type])
        .replace(/[^\d^]+/g, "");
      if(type === 'hours'){
        if(Number(str)>23){
          str = 23;
        }
      }
      if(type === 'minutes'){
        if(Number(str)>59){
          str = 59;
        }
      }
      this[type] = str;
      this.p_task_detail.modified = true;
      this.modifiedSign();
    },
    changeInput(){
      this.$set(this.p_task_detail, 'content', this.text);
      this.$api.moduleTask.signWidgetModified(this.p_task_detail);
      this.modifiedSign();
    },
    async init(){
      const {p_task_detail, status_projection_screen} = this;
      const {state} = store;
      const {task} = state;
      const user_id = this.$tools.getUserId();
      // if(Number(task.task.userId) !== Number(user_id) && task.task.id){
      //   this.editable = false;
      // }
      if(p_task_detail.userId && Number(p_task_detail.userId) !== Number(user_id) && task.task.id){
        this.editable = false;
      }
      const {cf_edit_data, screenList, screens} = p_task_detail;
      let content = p_task_detail.content;
      let countdown = p_task_detail.countdown;
      if(cf_edit_data){
        if(cf_edit_data.content !== undefined){
          content = cf_edit_data.content;
        }
        // if(cf_edit_data.screens !== undefined){
        //   screens = cf_edit_data.screens;
        // }
        if(cf_edit_data.countdown !== undefined){
          countdown = cf_edit_data.countdown;
        }
      }
      this.content = content;
      this.generateTime(countdown);

      // 获取屏幕列表
      // if(screenList && screenList.length){
      //   this.$set(this, 'screen_list', this.$types.transformObjectForList({
      //     list: screenList,
      //     obj: PScreen,
      //   }));
      // }else{
      await this.getUserScreen();
      // }
      
      this.$nextTick(function(){
        if(screens){
          const arr = screens.split(',');
          for(const item of arr){
            const unit = this.screen_list.find(sl_item=>{
              return sl_item.screenNo === item;
            })
            if(unit){
              unit.cf_selected = true;
            }
          }
        }  
      })
      
    },
    generateTime(countdown){
      let days = '';
      let hours = '';
      let minutes = '';
      // const c = 59 + 2*60 + 3*24*60; 4499
      if(countdown){
        countdown = Number(countdown);
        minutes = countdown % 60 || ''; // 4499 % 60
        hours   = ((countdown - minutes) / 60) % 24 || ''; // ((4499 - 59) / 60) % 24
        days    = (((countdown - minutes) / 60) - hours) / 24 || ''; (((4499 - 59) / 60) - 2) / 24
      }
      this.days    = days;
      this.hours   = hours;
      this.minutes = minutes;
    },
    modifiedSign(){
      this.$emit('handleMofifiedSign', {item: this.p_task_detail, parent: this.parent});
    },
    async getUserScreen(){
      const res = await this.$api.wisdom.getUserScreen();
      // // console.log(res);
      this.$set(this, 'screen_list', res);
    },
    handleSelectAll(){
      
      const {selected_all, screen_list} = this;
      // if(selected_all){
      //   screen_list.forEach(item=>{
      //     this.$set(item, 'cf_selected', false);
      //   })
      // }else{
        screen_list.forEach(item=>{
          if(selected_all){ // 取消全选
            // this.$set(item, 'cf_selected', false);
            this.removeScreen(item);
          }else{ // 全选
            if(!item.cf_selected){ 
              // this.$set(item, 'cf_selected', true);
              this.addScreen(item);
            }
          }
        })
      // }
      this.modifiedSign();
      // console.log(this.item.screenList)
    },
    getLock(){
      // if(!this.editable){
      //   return;
      // }
      if(!this.canEditable()){
        return;
      }
      this.$emit('handleLock', this.p_task_detail)

    },
    async touchSelect(){
      const {p_task_detail} = this;
      if(!this.canEditable()){
        setTimeout(() => {
          
          this.switch_screen = false;
        }, 50);
        return;
      }
      const {id} = p_task_detail;
      if(id){
        const res = await this.$api.moduleTask.getLock(p_task_detail);
        if(!res){
          this.switch_screen = false;
        }
      }
    },
    canEditable(){
      const {editable, status_projection_screen} = this;
      if(!editable){
        this.$notice({
          desc: '该控件创建者可以编辑',
        })
      }
      if(status_projection_screen){
        this.$notice({
          desc: '正在投屏中,请先结束投屏',
        })
      }
      return editable && !status_projection_screen;
    },
    toggleSelect(p_screen){
      const {p_task_detail, } = this;
      if(p_screen.cf_selected){ // 移除选项
        this.removeScreen(p_screen);
        // const index = item.screenList.findIndex(unit=>{
        //   return unit.id === p_screen.id;
        // })
        // // console.log(p_screen, index)
        // if(index != -1){
        //   item.screenList.splice(index, 1);
        // }
      }else{ // 增加选项
        this.addScreen(p_screen)
        // if(!item.screenList){
        
        //   this.$set(item, 'screenList', [p_screen]);
        // }else{
        //   item.screenList.push(p_screen);
        // }
      }
     
      this.modifiedSign();
      // console.log(this.item.screenList);
    },
    addScreen(p_screen){
      const {p_task_detail, } = this;
      if(!p_task_detail.screenList){
        
        this.$set(p_task_detail, 'screenList', [p_screen]);
      }else{
        p_task_detail.screenList.push(p_screen);
      }
      this.$set(p_screen, 'cf_selected', true);
    },
    removeScreen(p_screen){
      const {p_task_detail, } = this;
      const index = p_task_detail.screenList.findIndex(unit=>{
        return unit.id === p_screen.id;
      })
      // console.log(p_screen, index)
      if(index != -1){
        p_task_detail.screenList.splice(index, 1);
      }
      this.$set(p_screen, 'cf_selected', false);
    },
    async endForScreen(){
      this.switch_projection = false;
      this.$openConfirm({
        title:'提示',
        desc:'是否确定结束投屏?',
        sure:'是',
        sureType: '', // 确定按钮样式 '','warn'
        cancel:'否',
        onSure: async ()=>{
          await this.p_task_detail.endForScreen();
        },
        onCancel:()=>{},
      })
      
    }
  },
  mounted(){
    this.init();
    // this.getUserScreen();
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_content_wisdom_page{
  padding    : 13px 10px;
  font-size  : 14px;
  line-height: 24px;
  color      : #333;
  input{
    background-color: transparent;
  }
  .separate{
    padding-right: 16px;
    margin-right : 16px;
    border-right : 1px solid #f4f4f4;
  }
  .projection_screen{
    color: #0054A7;
  }
  .content_group{
    .content{
      @include placeholder(#ccc);
      border     : none;
      outline    : none;
      font-size  : 14px;
      line-height: 24px;
      color      : #333;
      width      : 200px;
    }
  }
  .time_group{
    input{
      @include placeholder(#ccc);
      box-sizing   : border-box;
      border       : none;
      outline      : none;
      font-size    : 14px;
      line-height  : 24px;
      color        : #333;
      width        : 28px;
      text-align   : right;
      padding-right: 8px;
      &.days{
        width: 38px;
      }
    }
    .text{
      margin: {
        right: 12px;
      }
      color: #ccc;
    }
  }
  .select_group{
    color   : #ccc;
    overflow: hidden;
    .select_btn_group{
      .text{
        @include single_line_intercept;
      }
      .text.active{
        color: #333;
      }
    }
  }
}

</style>
<style lang="scss">
.components_task_main_widget_content_wisdom_page_projection_screen{
  padding    : 5px 0;
  min-width  : 100px;
  margin-left: -10px;
  margin-top : 2px!important;
  .button{
    @include transition;
    font-size  : 14px;
    color      : #333;
    line-height: 34px;
    text-align : center;
    &:hover{
      background-color: #eee;
    }
  }
}
.components_task_main_widget_content_wisdom_page_select_group{
  padding    : 5px 0;
  min-width  : 130px;
  margin-left: -10px;
  margin-top : 2px!important;
  .list_group{
    max-height: 195px;
    overflow  : auto;
    .all, .unit{
      @include transition;
      width       : 100%;
      padding-left: 16px;
      &:hover{
        background-color: #ccc;
      }
    }
    .none{
      line-height: 24px;
    }
    .icon{
      @include transition;
      background: {
        image: url(~@/assets/images/task/wisdom/unselected.png);
      }
      width       : 16px;
      height      : 16px;
      margin-right: 10px;
      &.active{
        background: {
          image: url(~@/assets/images/task/wisdom/selected.png);
        }
      }
    }
    .text{
      font-size  : 14px;
      color      : #333;
      line-height: 34px;
      
    }
    .list_box{
      @include scrollbar;
      width   : 100%;
      flex    : 1;
      overflow: auto;
    }
  }
}
</style>
