<template>
  <div
    class="components_task_main_widget_content_part_file_page bbox d-flex align-center"
    :class="{failed, cp: preview}"
    @click.stop="previewFile();"
  >
    <div class="file_icon flex-shrink-0"
      :class="[$tools.getExtName(file.filename)]"
    ></div>
    <div class="info_group d-flex align-center justify-space-between">
      <div class="detail_group d-flex flex-column justify-space-between align-start">
        <div class="filename">{{file.filename}}</div>
        <div class="uploading d-flex align-center un_sel"
          v-if="uploading"
        >
          <div class="progress_text">{{memory(file.filesize)}}</div>
          <div class="progress_text ml-6"
            v-if="file.progress && file.progress != 0 && !failed"
          >正在上传 {{file.progress}}%</div>
          <div class="progress_text ml-6"
            v-if="!file.progress || file.progress == 0 && !failed"
          >等待上传</div>

          <div class="btn failed"
            v-if="failed"
          >上传失败</div>
          <div class="btn cp re_upload"
            v-if="failed"
            @click.stop="reUpload"
          >重新上传</div>
        </div>
        <div class="details d-flex align-center"
          v-if="details"
        >
          <div class="filesize">{{memory(file.filesize)}}</div>
          <div class="time">{{file.createTime | formatDate}}</div>
        </div>

      </div>
      <div class="fun_group d-flex align-center flex-shrink-0"
        @click.stop="$api.stopPropagation($event);"
        v-if="show_icon && !shopping_mail"
      >
        <div class="button download"
          v-if="download"
          @click.stop="downloadFile();"
        ></div>
        <div class="button delete"
          @click.stop="deleteFile()"
          v-if="deleteable"
        ></div>
        <div class="button select"
          v-if="selectable"
          :class="{on: file.select}"
          @click.stop="selectFile()"
        ></div>
        <div class="button sort"
          v-if="sort"
        ></div>
      </div>
      <div class="progress"
        v-if="file.progress && !file.url"
        :style="{width: `${file.progress || 0}%`}"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    file:{
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    show_icon: {
      type: Boolean,
      default: true,
    },
    system: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
    
  },
  computed: {
    uploading(){
      const {file} = this;
      if(!file.url){
        return true;
      }
      return false;
    },
    details(){
      const {file} = this;
      if(file.url){
        return true;
      }
      return false;
    },
    failed(){
      const {file} = this;
      if(file.status === 'failed'){
        return true;
      }
      return false;
    },
    preview(){
      const {file, sort} = this;
      const {filename, url} = file;
      const filetype = this.$tools.getExtName(filename);
      if(sort){
        return false;
      }
      if(url){
        if(filetype === 'mp4'){
          return true;
        }
        if(filetype === 'doc'){
          return true;
        }
        if(filetype === 'xls'){
          return true;
        }
        if(filetype === 'ppt'){
          return true;
        }
        if(filetype === 'pdf'){
          return true;
        }
        if(filetype === 'pic'){
          return true;
        }
      }
      return false;
    },
    download(){
      const {sort} = this;
      if(sort){
        return false;
      }
      if(this.system){
        return false;
      }
      return true;
    },
    deleteable(){
      const {sort, editable} = this;
      if(sort){
        return false;
      }
      return editable;
    },
    selectable(){
      const {sort} = this;
      if(sort){
        return false;
      }
      return true;
    }
  },
  methods: {
    /**内存大小 */
    memory(size) {
      if (size >= 1024 * 1024) {
        return (size / (1024 * 1024.0)).toFixed(2) + "G";
      } else if (size < 1024 * 1024 && size >= 1024) {
        return (size / 1024.0).toFixed(2) + "M";
      } else {
        return size + "K";
      }
    },
    reUpload(){
      this.$emit('handleUpload', this.file);
    },
    downloadFile(){
      this.$emit('handleDownload', this.file);
    },
    deleteFile(){
      const {file} = this;
      const {url, cancelSource} = file;
      if(!url && cancelSource){
        this.$emit('handleCancel', this.file);
      }else{
        this.$emit('handleDelete', this.file);
      }

    },
    selectFile(){
      this.$emit('handleSelect', this.file);
    },
    previewFile(){
      const {preview} = this;

      if(preview){
        this.$emit('handlePreview', this.file);
      }
    }
  },
  mounted(){
    // // console.log(this.file);
  }
}

/**
 * fileList
{
chatId: 132
conId: 46779
createTime: 1627281886000
filename: "交通_124.50元_2021.05.24_滴滴出行科技有限公司_011002000911_53155319.pdf"
filesize: 39
filetype: "pdf"
height: null
id: 24011
isTag: 0
taskId: 5688
taskNotice: 0
teach: null
type: null
url: "pmcs/2021/07/26/1627281884540855731.pdf"
userId: 4360
width: null

}
 */
</script>
<style lang="scss" scoped>
@mixin file_icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
@mixin fun_button($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/widget_#{$type}_button.png);
  }
}
.components_task_main_widget_content_part_file_page{
  @include transition;
  height: 60px;
  padding-left: 10px;
  font-size: 12px;
  color: #9F9F9F;
  line-height: 17px;
  overflow: hidden;
  // &.failed{
  //   background-color: #FDF8F7;
  //   &:hover{
  //     background-color: #FDF8F7;
  //   }
  // }
  // &:hover{
  //   background-color:#eee;
  // }
  .uploading{
    .btn{
      padding-left: 10px;
      &.failed{
        color: #D82E0D;
      }
      &.re_upload{
        color:#2B70B4;
      }
    }
  }
  .info_group{
    position: relative;
    height: 100%;
    z-index: 2;
    flex: 1;
    .detail_group{
      flex: 1;
      .filesize{
        padding-right: 10px;
      }
    }
    .progress{
      position: absolute;
      z-index: 3;
      height: 2px;
      background-color: $main_blue;
      left:0;
      bottom: 0;
    }
  }
  .file_icon{
    // ['doc','xls', 'ppt','mp4', 'pic', 'zip', 'pdf', 'unknown'];
    background-image: url(~@/assets/images/task/unknown.png);
    width: 32px;
    height: 32px;
    margin-right: 10px;
    @include file_icon(doc);
    @include file_icon(xls);
    @include file_icon(ppt);
    @include file_icon(mp4);
    @include file_icon(pic);
    @include file_icon(zip);
    @include file_icon(pdf);
  }
  .filename{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    padding-bottom: 5px;
    word-break: break-all;
  }
  .fun_group{
    padding-right: 30px;
    .button{
      @include background(16px, 16px);
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      @include fun_button(select);
      @include fun_button(delete);
      @include fun_button(download);
      &:first-child{
        margin-left: 0;
      }
      &.select.on{
        background-image: url(~@/assets/images/task/widget_select_button_on.png)
      }
      &.sort{
        @include background(24px, 17px);
        width: 24px;
        height: 17px;
        background-image: url(~@/assets/images/file/sort.png);
      }
    }
  }
}
</style>