var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_title_fun_group_page fun_group flex-shrink-0",
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            trigger: "click",
            "visible-arrow": false,
            "popper-class":
              "components_task_main_widget_title_fun_group_page_menu",
          },
          model: {
            value: _vm.switch_menu,
            callback: function ($$v) {
              _vm.switch_menu = $$v
            },
            expression: "switch_menu",
          },
        },
        [
          _c(
            "div",
            { staticClass: "menu_group" },
            [
              _vm.relevancy_status && _vm.delete_right
                ? [
                    _vm.can_relevancy && !_vm.item.islink
                      ? _c(
                          "div",
                          {
                            staticClass: "button",
                            on: {
                              click: function ($event) {
                                return _vm.openRelevancy()
                              },
                            },
                          },
                          [_vm._v("关联")]
                        )
                      : _vm._e(),
                    _vm.can_relevancy && _vm.item.islink
                      ? _c(
                          "div",
                          {
                            staticClass: "button",
                            on: {
                              click: function ($event) {
                                return _vm.deleteRelevancy()
                              },
                            },
                          },
                          [_vm._v("删除关联")]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.delete_right
                ? _c(
                    "div",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.doDelete()
                        },
                      },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _vm.reminder_status
                ? _c(
                    "div",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.setReminder()
                        },
                      },
                    },
                    [_vm._v("设置提醒")]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("template", { slot: "reference" }, [
            _c("div", {
              staticClass: "menu button",
              on: {
                click: function ($event) {
                  return _vm.handleClick()
                },
              },
            }),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }