var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right-start",
        trigger: "click",
        "visible-arrow": false,
        "popper-class":
          "components_task_main_widget_relevancy_add_button_page_popover",
      },
      model: {
        value: _vm.switch_menu,
        callback: function ($$v) {
          _vm.switch_menu = $$v
        },
        expression: "switch_menu",
      },
    },
    [
      _c(
        "div",
        { staticClass: "add_group" },
        [
          _vm._l(_vm.list, function (val, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "item cp un_sel d-flex align-center",
                  on: {
                    click: function ($event) {
                      return _vm.clickNewWidget({ type: val.type })
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon", class: [val.name] }),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(val.title)),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("template", { slot: "reference" }, [
        _c("div", {
          staticClass:
            "components_task_main_widget_relevancy_add_button_page add cp",
          on: {
            click: function ($event) {
              _vm.setIndex()
              _vm.doAdd()
            },
          },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }