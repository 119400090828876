<template>
  <div
    class="components_task_main_widget_wrapper_page bbox"
    :style="{'z-index': index }"
  >
    <div class="contents bbox"
      :style="{'z-index': index }"
      :class="{relevancy: parent}"
    >
      <widget-title
        :p_task_detail="p_task_detail"
        @handleAdd="touchAdd"
      ></widget-title>
      <template
        v-if="p_task_detail && p_task_detail.type"
      >
        <content-text
          v-if="p_task_detail.type === 1"
          :p_task_detail="p_task_detail"
        ></content-text>
        <content-number
          v-if="p_task_detail.type === 2"
          :p_task_detail="p_task_detail"
        ></content-number>
        <content-money
          v-if="p_task_detail.type === 3"
          :p_task_detail="p_task_detail"
        ></content-money>
        <content-time
          v-if="p_task_detail.type === 4"
          :p_task_detail="p_task_detail"
        ></content-time>
        <content-file
          v-if="p_task_detail.type === 6"
          :p_task_detail="p_task_detail"
          ref="file"
        ></content-file>
        <content-hyperlink
          v-if="p_task_detail.type === 7"
          :p_task_detail="p_task_detail"
          ref="hyperlink"
        ></content-hyperlink>
        <content-picture
          v-if="p_task_detail.type === 9"
          :p_task_detail="p_task_detail"
          ref="picture"
        ></content-picture>
        <content-contact
          v-if="p_task_detail.type === 10"
          :p_task_detail="p_task_detail"
          ref="contact"
        ></content-contact>
        <content-wisdom
          v-if="p_task_detail.type === 11"
          :p_task_detail="p_task_detail"
          ref="wisdom"
        ></content-wisdom>
      </template>
      <!-- 关联区 -->
      <div class="relevancy_wrapper"
        v-if="p_task_detail.islink "
      >
        <div class="title_group d-flex align-center bbox">
          <div class="name_group d-flex align-center">
            <div class="icon cp"
              :class="{close: !p_task_detail.switch_relevancy}"
              @click="swicthRelevancy();"
            ></div>
            <div class="name un_sel cp"
              @click="swicthRelevancy();"
            >关联区</div>
            <!-- <div class="add cp"></div> -->
            <relevancy-add-button
              :large_task="large_task"
              :data="data"
              :backup="backup"
              :p_task_detail="p_task_detail"
            ></relevancy-add-button>
          </div>
          <div class="line"></div>
          <div class="delete"
            @click="deleteRelevancy(p_task_detail)"
          ></div>
        </div>
        <widget-container
          v-if="p_task_detail.switch_relevancy"
          class="relevancy_container"
          :parent="p_task_detail"
          :large_task="large_task"
          :data="data"
          :backup="backup"
          :list="p_task_detail.controlLinkList"
          ref="container"
          @setZIndex="setZIndex"
        >
          <template
            v-slot
          >
            <div class="relevancy_hint bbox">点击上方加号或拖拽右侧控件至此进行添加</div>
          </template>
        </widget-container>

        
      </div>
      <div class="fun_group d-flex align-center justify-end bbox"
        v-if="islock"
      > 
        <template
          v-if="projection_screen_status"
        >
          <div class="button projection_screen"
            :class="{active: projection_screen_start}"
            @click.stop="doProjectionScreen()"
          >{{projection_screen_start?'结束':'开始'}}投屏</div>
        </template>
        <template
          v-if="islock"
        >
          <div class="button btn_dark"
            @click="doSendWidget();"
          >完成</div>
          <div class="button btn_light"
            @click="doRepeal();"
          >撤销</div>
        </template>
      </div>
    </div>
    <expand-bar
      v-if="0 && expand_status"
      :remindList="item.remindList"
      :recycle="recycle"
      @handleOpenReminder="handleOpenReminder"
    ></expand-bar>
    <!-- <reminder
      v-if="item.switch_reminder"
      :data="reminder_data"
      :list="reminder_list"
      :type="reminder_type"
      :conId="item.id || null"
      :taskId="$store.state.task_config.task_id || null"
      :top="true"
      @handleClose="closeReminder"
      @handleComplete="reminderComplete"
      @handleDelete="deleteReminder"
    ></reminder>  -->
  </div>
</template>
<script>
import {PTaskDetail} from '@/types'; 
import WidgetTitle from './Title.vue';
import WidgetContainer from '../WidgetContainer.vue';
import RelevancyAddButton from './Relevancy/AddButton.vue';
import ContentText from './Content/Text.vue';
import ContentNumber from './Content/Number.vue';
import ContentMoney from './Content/Money.vue';
import ContentTime from './Content/Time.vue';
import ContentFile from './Content/File.vue';
import ContentHyperlink from './Content/Hyperlink.vue';
import ContentPicture from './Content/Picture.vue';
import ContentContact from './Content/Contact.vue';
import ContentWisdom from './Content/Wisdom.vue';
import Reminder from '@/components/PopUp/Reminder.vue';
import ExpandBar from './ExpandBar.vue';
import tools from '@/api/tools.js'
export default {
  components: {
    WidgetTitle,
    WidgetContainer,
    RelevancyAddButton,
    ContentText,
    ContentNumber,
    ContentMoney,
    ContentTime,
    ContentFile,
    ContentHyperlink,
    ContentPicture,
    ContentContact,
    ContentWisdom,
    Reminder,
    ExpandBar,

  },
  props: {
    large_task: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
    p_task_detail: {
      type: Object,
      default: undefined,
    },
    parent: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    data(){
      const {large_task} = this
      const {data} = large_task
      return data
    },
    // 投屏相关状态 是否显示投屏按钮
    projection_screen_status(){
      const {p_task_detail} = this
      const {type, id} = p_task_detail
      if(!id){
        return false
      }
      return type == 11
    },
     // 投屏是否开始
    projection_screen_start(){
      const {p_task_detail} = this
      const {screenstatus} = p_task_detail
      return  screenstatus === 1
    },
    islock(){
      const {p_task_detail} = this
      const {islock, userlock} = p_task_detail
      const userid = tools.getUserId()
      return islock && (Number(userid) === Number(userlock))
    },
  },
  data(){
    return{
      reminder_data: {},
      index: 3,
    }
  },
  mounted(){
  },
  methods: {
    setZIndex(zindex){
      const {parent} = this;
      if(parent){
        this.$emit('setZIndex', zindex);
      }else{
        this.index = zindex;
      }
    },
    async swicthRelevancy(){
      const {p_task_detail} = this;
      const {islink, controlLinkList, id} = p_task_detail;
      if(islink && controlLinkList === null){
        // // console.log('加载关联')
        const res = await this.$api.moduleTask.getRelevancy({id});
        if(res.message === 'success' && res.contents.controlLinkList){
          this.$set(p_task_detail, 'controlLinkList', res.contents.controlLinkList);
        }
        // 将关联数据添加到原始数据中
        const {task_original} = this.$store.state;
        const {taskDeatails} = task_original;
        const index = taskDeatails.findIndex(unit=>{
          return unit.id === id;
        })
        if(index != -1){
          taskDeatails[index].controlLinkList = (this.$tools.deepClone(res.contents.controlLinkList));
        }
      }
      // this.$set(this.p_task_detail, 'switch_relevancy', !this.item.switch_relevancy);
      this.p_task_detail.switch_relevancy = !this.p_task_detail.switch_relevancy
    },
    touchAdd(){
      const {p_task_detail} = this
      if(p_task_detail.type === 6){
        this.$refs.file.touchAdd()
      }
      if(p_task_detail.type === 7){
        this.$refs.hyperlink.touchAdd()
      }
      if(p_task_detail.type === 9){
        this.$refs.picture.touchAdd()
      }
      if(p_task_detail.type === 10){
        this.$refs.contact.touchAdd()
      }
    },
  }

}
</script>
<style lang="scss" scoped>
.components_task_main_widget_wrapper_page .contents{
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  // padding-bottom: 10px;
  // padding: 10px 0;
  position: relative;
  z-index: 3;
  word-break : normal; 
  &.heightlight{
    border: 3px solid #FFD4A0;
  }
  &.relevancy{
    background: #F4F7FC;
  }
  .text{
    textarea{
      border:none!important;
    }
  }
  .relevancy_wrapper{
    .title_group{
      width: 100%;
      padding: 13px 10px;
      .name_group{
        .icon{
          @include background(10px, 11px);
          width: 11px;
          height: 11px;
          background-image: url(~@/assets/images/task/relevancy_open.png);
          &.close{
            @include background(10px, 9px);
            background-image: url(~@/assets/images/task/relevancy_close.png);
          }
        }
        .name{
          padding: 0 5px;
          font-size: 14px;
          color: #555555;
          line-height: 19px;
        }
      
      }
      .line{
        flex: 1;
        border-bottom: 1px dashed #d8d8d8;
        margin: 0 10px;
      }
      .delete{
        @include background;
        width: 26px;
        height: 26px;
        background-image: url(~@/assets/images/task/relevancy_delete.png);
      }
    }
  }
  .relevancy_container{
    padding: 0 10px 10px;
  }
  .relevancy_hint{
    height: 40px;
    background: #F4F7FC;
    border-radius: 4px;
    font-size: 12px;
    color: #88A0B5;
    line-height: 40px;
    width: 100%;
    padding: 0 10px;
  }
  .fun_group{
    padding: 0 10px 7px;
    .button{
      @include transition;
      line-height: 26px;
      font-size: 12px;
      padding: 0 12px;
      margin-left: 10px;
      border-radius: 4px;
      box-sizing: border-box;
      &.projection_screen{
        border: 1px solid $main_blue;
        line-height: 24px;
        color: $main_blue;
        &.active{
          border-color: #C84B33;
          color: #C84B33;
        }
      }
    }
  }
}

</style>
