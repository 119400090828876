var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_widget_expand_bar_page bbox d-flex align-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "reminder_group d-flex align-center cp",
          on: {
            click: function ($event) {
              return _vm.openReminder()
            },
          },
        },
        [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/images/task/reminder_icon.png"),
              alt: "",
            },
          }),
          _vm.details_group
            ? _c("div", { staticClass: "details_group" }, [
                _vm._v(_vm._s(_vm.reminder_details)),
              ])
            : _vm._e(),
          _vm.reminder_sketch
            ? _c("div", { staticClass: "sketch" }, [
                _vm._v(_vm._s(_vm.remindList.length) + "个提醒"),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }