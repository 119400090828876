<template>
  <el-popover
    placement="right-start"
    trigger="click"
    :visible-arrow="false"
    popper-class="components_task_main_widget_relevancy_add_button_page_popover"
    v-model="switch_menu"
  >
    <div class="add_group">
      <template
        v-for="(val,index) in list"
      >
        <div class="item cp un_sel d-flex align-center"
          :key="index"
          @click="clickNewWidget({type:val.type});"
        >
          <div class="icon"
            :class="[val.name]"
          ></div>
          <div class="text">{{val.title}}</div>
        </div>
      </template>
    </div>
    <template slot="reference">
      <div class="components_task_main_widget_relevancy_add_button_page add cp"
        @click="setIndex();doAdd();"
      ></div>
    </template>
  </el-popover>
</template>
<script>
import {PTaskDetail} from '@/types';
import store from '@/store'
export default {
  props: {
    p_task_detail: {
      type: Object,
      default: undefined,
    },
    large_task: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return {
      switch_menu: false,
      list: [
        {name:'text', type: 1, title: '文本'},
        {name: 'number', type: 2, title: '数字'},
        {name: 'money', type: 3, title: '金额'},
        {name: 'time', type: 4, title: '时间'},
        // {name: 'formula', type: 5, title: ''},
        {name: 'file', type: 6, title: '附件'},
        {name: 'hyperlink', type: 7, title: '链接'},
        // {name: 'location', type: 8, title: ''},
        {name: 'picture', type: 9, title: '图片'},
        {name: 'contact', type: 10, title: '名片'},
        {name: 'wisdom', type: 11, title: '智慧桌'},
      ]
    }
  },
  methods: {
    doAdd(){
      this.$emit('handleClick');
    },
    setIndex(){
      this.$utils.setPopoverZIndex('.components_task_main_widget_relevancy_add_button_page_popover');
    },
    /**
     * 点击 新建控件
     */
    async clickNewWidget({type}){
      console.log(this.p_task_detail)
      const {p_task_detail} = this;
      const {controlLinkList, islink, id} = p_task_detail;
      const task_detail = this.newWidget({type});
      controlLinkList.push(new PTaskDetail(task_detail));
      this.switch_menu = false;
    },
    /**
     * 新建控件
     */
    newWidget({type}){
      const {large_task} = this
      const {create_num} = large_task
      const widget_sort = create_num
      large_task.create_num += 1
      let task_detail = {
        type: type,
        remark : '', // 描述/标题
        content : '', // 内容/值
        controlLinkList: [],
        widget_sort, // TODO 废弃
        create_num, 
        remindList: [],
      }
      if(type == 2){
        // 数值
        task_detail.unit = ''; // 单位
      }else if(type == 3){
        //金额
        task_detail.unit = '元';
      }else if(type == 4){
        task_detail.timetype = 1;
      }else if(type == 7){
        task_detail.hyperlink_list = [];
      }else if(type == 9){
        task_detail.fileList = [];
      }else if(type == 6){
        task_detail.fileList = [];
      }
      return task_detail;
    },
  }
}
</script>
<style lang="scss" scoped>

.components_task_main_widget_relevancy_add_button_page{
  &.add{
    @include background(16px, 16px);
    width: 20px;
    height: 20px;
    background-image: url(~@/assets/images/task/add_button.png);
  }
}
</style>
<style lang="scss">
@mixin icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
.components_task_main_widget_relevancy_add_button_page_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  .add_group{
    .item{
      @include transition;
      @include bbox;
      height: 34px;
      padding-left: 16px;
      background-color:#fff;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      &:hover{
        background-color: #eee;
      }
      .icon{
        @include background(18px 18px);
        width: 18px;
        height: 18px;
        margin-right: 5px;
        @include icon(text);
        @include icon(number);
        @include icon(money);
        @include icon(time);
        @include icon(file);
        @include icon(hyperlink);
        @include icon(picture);
        @include icon(contact);
        @include icon(wisdom);
      }
    }
  }
}
</style>